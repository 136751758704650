import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './auth.service';
import { ExampleVideo } from 'src/app/core/models/example-video-upload';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { appApiResources } from './api.constant';

@Injectable({
  providedIn: 'root'
})
export class DemoTestedVideoService {
  constructor(private http: HttpClient, private _authSer: AuthService) {}

  getDemoTestedVideoDetails(): Observable<any> {
    return this.http
      .get(appApiResources.adminDummyTestTesterDetails)
      .pipe(catchError(this.handleError));
  }

  approveRejctTest(parms): Observable<any> {
    return this.http
      .post(appApiResources.getApproveRejectTest,parms)
      .pipe(catchError(this.handleError));
  }

  checkApproveAndReject(): Observable<any> {
    return this.http
      .get(appApiResources.getApproveRejectTest)
      .pipe(catchError(this.handleError));
  }

  getDeclinedTesterList() {
    return this.http.get(appApiResources.adminRejectedTester)
    .pipe(catchError(this.handleError));
  }

  deleteDeclinedTester(email){
    const options = {
      params: new HttpParams()
        .set('email', email),
      reportProgress: false
    };
    return this.http.delete(appApiResources.adminRejectedTester,options)
    .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }
}
