import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { DemoTestedVideoService } from "src/app/shared/services/demo-tested-video.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-declined-tester-list",
  templateUrl: "./declined-tester-list.component.html",
  styleUrls: ["./declined-tester-list.component.css"],
})
export class DeclinedTesterListComponent implements OnInit {
  pushDataInList: any[] = [];
  error: any;

  allTester: [] = [];
  data = [];

  displayedColumns: any[] = [
    "declined_tester",
    "delete",
  ];

  constructor(private _testedDemoSer: DemoTestedVideoService) {}

  ngOnInit() {
    this.declinedTesterList();
  }

  declinedTesterList() { 
    this._testedDemoSer.getDeclinedTesterList().subscribe(
      (res) => {
        this.pushDataInList.push(this.displayedColumns);
        this.pushDataInList.push(res['data']);
      },
      (error) => (this.error = error)
    );
  }

  // getSingleInfo(event) {
  //   console.log("getSingleData", event);
  // }

  getDeclinedEmail(event){
    console.log("getSingleData", event.email);
      Swal.fire({
        title: "Are you sure?",
        text: "You want to delete this tester!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this._testedDemoSer.deleteDeclinedTester(event.email).subscribe(
            (res) => {
              if (res['status'] == 200) {
                this.declinedTesterList();
                Swal.fire(
                  "Deleted!",
                  "This tester has been deleted permanently in the database.",
                  "success"
                );
              } else {
                Swal.fire("Failed!", res['message'], "error");
              }
            },
            (error) => {
              Swal.fire("Error!", "Something went wrong .", "error");
            }
          );
        }
      });
  }
}
