import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
// import { DashboardClientService } from 'src/app/shared/services/dashboard-client.service';
// import { TesterTestService } from 'src/app/shared/services/tester-test.service';
import { Subscription } from 'rxjs';
import { DashboardService } from 'src/app/shared/services/dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit,OnDestroy {
  allDashboardData: Subscription;
  error: any;
  allData: [];
  totalClient: any;
  totalTester: any;
  totalTest: any;

  constructor( private _router: Router,private _dashboardServices:DashboardService) { }

  ngOnInit() {
    this.getAllData()
  }

  getAllData(){
    this.allDashboardData = this._dashboardServices.getAllData()
      .subscribe(res=>{
        this.allData = res.data
        this.totalClient = this.allData['total_clients']
        this.totalTester = this.allData['total_tester']
        this.totalTest = this.allData['total_test']
      },
      error => this.error = error)
  }
  
  ngOnDestroy(){
    // this.testerGetData.unsubscribe()
  }
}
