import { Component, OnInit, ViewChild } from "@angular/core";
import { environment } from "src/environments/environment";
import { TestsService } from "src/app/shared/services/tests.service";

import {
  Sort,
  MatTableDataSource,
  MatPaginator,
  MatSort,
} from "@angular/material";

export interface PeriodicElement {
  test_name: string;
  creation_date: number;
  id: number;
  project_name: string;
  status: any;
  view: any;
  date_of_tested;
  created_by;
  tested_by;
}

var ELEMENT_DATA: PeriodicElement[] = [];
var ELEMENT_DATA1: PeriodicElement[] = [];

@Component({
  selector: "app-tests",
  templateUrl: "./tests.component.html",
  styleUrls: ["./tests.component.css"],
})
export class TestsComponent implements OnInit {
  EnvApiUrl : string = environment.apiUrl;
  tests: any;
  allTestsList: [];
  allTestedList: [] = [];
  testedShow = false;
  filterData: any;
  showTest: boolean = false;
  testedItem: [] = [];
  testID: any;
  singleID: any;
  userID: any;
  allMarkerList: Object;
  searchText: string;
  searchText1: string;
  allSortedTest = [];
  pageLength: number = 0;
  showTestDetails: boolean = false;
  pageIndex: number = 0;
  testersDetials: any;
  displayedColumns: string[] = [
    "id",
    "test_name",
    "creation_date",
    "created_by",
    "project_name",
    "status",
    "view",
    "tester_info",
  ];
  displayedColumns1: string[] = [
    "id",
    "test_name",
    "date_of_tested",
    "tested_by",
    "project_name",
    "status",
    "view",
    "tester_info",
  ];

  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  dataSource1 = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA1);
  EADDPStages;
  selectStage;
  showDataForm;
  // EADDPStages = [];

  @ViewChild(MatPaginator, { static: false }) set content(
    paginator: MatPaginator
  ) {
    this.dataSource.paginator = paginator;
  }

  @ViewChild(MatSort, { static: false }) set conten1t(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @ViewChild("TableTwoPaginator", { static: false }) set content2(
    paginator1: MatPaginator
  ) {
    this.dataSource1.paginator = paginator1;
  }

  @ViewChild(MatSort, { static: false }) set conten1t3(sort1: MatSort) {
    this.dataSource1.sort = sort1;
  }

  // @ViewChild('TableTwoSort', { static: false }) set conten1t3(sort1: MatSort) {
  //   this.dataSource1.sort = sort1;
  // }

  constructor(private _tests: TestsService) { }

  ngOnInit() {
    this.allTest();
    this._tests.getAllTestsList().subscribe((res) => {
      let all_test = res.data.all_test.map((test) => {
        return {
          ...test,
          statusMessage: `status-${this.getBadgeLabel(
            test.test_status
          ).labelText.toLowerCase()}`,
        };
      });
      this.dataSource = new MatTableDataSource<PeriodicElement>(all_test);
      this.dataSource1 = new MatTableDataSource<PeriodicElement>(
        res.data.tested_test
      );

      //   this.dataSource.paginator = this.paginator;
      // this.dataSource.sort = this.sort;
      // this.allList =res.data.user_details;
    });

    this.showTest = true;
    // this.getAllMarkerList()
  }

  viewTestDetialsFun(testId) {
    this.showTestDetails = true;
    let jsonTestId = { "test_id": testId }
    this._tests.getTesterDetials(jsonTestId).subscribe((res) => {
      this.testersDetials = res.data;
    });
  }

  closeTestDetailsFun() {
    this.showTestDetails = false;
    this.ngOnInit();
  }

  allTest() {
    this.tests = this._tests.getAllTestsList().subscribe((res) => {
      this.allTestedList = res.data.tested_test;

      this.allTestsList = res.data.all_test;

      this.filterData = this.allTestsList;
      this.testedShow = true;
      this.allSortedTest = this.filterData;
      this.pageIndex = 0;
      this.pageLength = this.filterData.length;
    });
    this.filterData = this.allTestsList;
    this.searchText = "";
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  applyFilter1(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource1.filter = filterValue;
  }

  testedTest() {
    this.testedShow = false;
    this.searchText1 = "";
    this.searchText = "";
  }




  statusColor(status) {
    let className = "";
    switch (status) {
      case "Invited":
        className = "badge badge-secondary";
        break;
      case "Completed":
        className = "badge badge-success";
        break;
      case "Accepted":
        className = "badge badge-warning";
        break;
      case "Expired":
        className = "badge badge-danger";
        break;
      default:
        break;

    }
    return {
      className,
    };
  }



  getBadgeLabel(statusCode) {
    let labelText = "";
    let className = "";
    let statusBtn = "";
    let editTest = "";
    let btnHide = "";
    switch (statusCode) {
      case 0:
        labelText = "Running";
        className = "badge badge-info";
        statusBtn = "Get videos";
        editTest = "";
        btnHide = "none";
        break;
      case 1:
        labelText = "pending";
        className = "badge badge-warning";
        statusBtn = "Get videos";
        editTest = "Edit Test";
        btnHide = "block";
        break;
      case 2:
        labelText = "Draft";
        className = "badge badge-warning";
        statusBtn = "Get videos";
        editTest = "Edit Test";
        btnHide = "none";
        break;
      case 6:
        labelText = "CANCELLED";
        className = "badge badge-danger";
        statusBtn = "Reactive Test";
        editTest = "Edit Test";
        btnHide = "block";
        break;
      case 3:
        labelText = "FINISHED";
        className = "badge badge-success";
        statusBtn = "Get new video";
        editTest = "";
        btnHide = "none";
        break;
      case 7:
        labelText = "CONFIRMATION REQUIRED";
        className = "draft red";
        statusBtn = "Reniew video";
        editTest = "Edit Test";
        btnHide = "block";
        break;
      case 5:
        labelText = "Archive";
        className = "badge badge-warning";
        statusBtn = "Get videos";
        editTest = "Edit Test";
        btnHide = "block";
        break;
      default:
        break;
    }
    return {
      labelText,
      className,
      statusBtn,
      editTest,
      btnHide,
    };
  }



  runningStatus() {
    // this.dataSource.filteredData = [];

    this.dataSource.filteredData.filter((res) => {
      if (res["test_status"] == 0) {
        this.dataSource.filteredData.push(res);
      }
    });
  }

  cancelStatus() {
    this.filterData = [];
    this.allTestsList.filter((res) => {
      if (res["test_status"] == 6) {
        this.filterData.push(res);
      }
    });
  }

  archieveStatus() {
    this.filterData = [];
    this.allTestsList.filter((res) => {
      if (res["test_status"] == 5) {
        this.filterData.push(res);
      }
    });
  }

  finishedStatus() {
    this.filterData = [];
    this.allTestsList.filter((res) => {
      if (res["test_status"] == 3) {
        this.filterData.push(res);
      }
    });
  }

  viewDataID(id, userId) {
    this.testID = id;
    this.userID = userId;
    this.showTest = false;
    this._tests.getMarkerList(userId).subscribe((res) => {
      this.allMarkerList = res;
    });
  }

  viewSingleTest(id) {
    this.singleID = id;
  }

  backBtn() {
    this.showTest = true;
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
