import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AuthGuard } from "./auth.guard";
import { LoginComponent } from "./core/auth/login/login.component";
import { DashboardComponent } from "./core/dashboard/dashboard.component";
import { AllUserComponent } from "./modules/users/all-user/all-user.component";
import { TesterComponent } from "./modules/testers/tester/tester.component";
import { TestsComponent } from "./modules/tests/tests/tests.component";
import { TestedTestComponent } from "./modules/tests/tested-test/tested-test.component";
import { FaqManagementComponent } from "./core/faq-management/faq-management.component";
import { NotificationManagementComponent } from "./core/notification-management/notification-management.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { VideosComponent } from "./core/videos/videos.component";
import { VideoInfoComponent } from "./core/videos/video-info/video-info.component";
import { TestedVideoComponent } from "./modules/tests/tested-video/tested-video.component";
import { DemoVideosComponent } from "./core/demo-videos/demo-videos.component";
import { DemoTestComponent } from "./core/demo-test/demo-test.component";
import { DemoTestedVideoComponent } from "./core/demo-tested-video/demo-tested-video.component";
import { TemplateManagementComponent } from "./core/template-management/template-management.component";
import { DeclinedTesterListComponent } from "./core/declined-tester-list/declined-tester-list.component";
import { PendingPaymentComponent } from "./modules/payment/pending-payment/pending-payment.component";
import { PaymentComponent } from "./modules/payment/payment/payment.component";
import { PaymentFailedComponent } from "./modules/payment/payment-failed/payment-failed.component";
import { PaymentSuccessComponent } from "./modules/payment/payment-success/payment-success.component";
import { PaymentDetailsComponent } from "./modules/payment/payment-details/payment-details.component";
import { TesterCriteriaComponent } from "./modules/testers/tester-criteria/tester-criteria.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },

  {
    path: "login",
    component: LoginComponent,
  },

  // New routing start
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "users",
    component: AllUserComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "testers",
    component: TesterComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "tests",
    component: TestsComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "tester-criteria",
    component: TesterCriteriaComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "tested-test",
    component: TestedTestComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "videos",
    component: TestedVideoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "videos/videos_info/:id",
    component: VideoInfoComponent,
    canActivate: [AuthGuard],
  },

  // {
  //   path: "static-page",
  //   component: FaqManagementComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: "coupon",
    component: NotificationManagementComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "demo-videos",
    component: DemoVideosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "demo-test",
    component: DemoTestComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "demo-tested-video",
    component: DemoTestedVideoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "template-management",
    component: TemplateManagementComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "declined-tester",
    component: DeclinedTesterListComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "payment",
    component: PaymentComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "payment/pending/list",
    component: PendingPaymentComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "payment/failed/list",
    component: PaymentFailedComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "payment/success/list",
    component: PaymentSuccessComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "payment/details",
    component: PaymentDetailsComponent,
    canActivate: [AuthGuard],
  },

  { path: "**", component: PageNotFoundComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
