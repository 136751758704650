import { Injectable } from '@angular/core';
import { HttpHeaders, HttpErrorResponse, HttpParams, HttpClient } from '@angular/common/http';
import { TokenParms } from 'src/app/core/models/token-parms';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class TestsService {
  player: any
  userRoleType = ["tester", "client"];
  // usertype = new Subject<any>();
  // role = this.usertype.asObservable();
  constructor(private http: HttpClient,private _authServices:AuthService,private _apiSer:ApiService
    
    ) {}

  getAllTestsList(): Observable<any> {
    return this.http
      .get(environment.apiUrl +"admin/test_management/")
      .pipe(catchError(this.handleError));
  }
  // deletetester(email): Observable<any> {
  //   const options = {
  //     headers: new HttpHeaders({
  //       Authorization: "Token " + this._authServices.getAuthorizationToken()
  //     }),
  //     params: new HttpParams()
  //     .set('email',email),
  //     reportProgress: false
  //   };
  //   return this.http
  //     .delete(environment.apiUrl + "admin/tester_management/",options)
  //     .pipe(catchError(this.handleError));
  // }

  getMarkerList(test) {
    const options = {
      headers: new HttpHeaders({
        Authorization: "Token " + this._authServices.getAuthorizationToken()
      }),
      params: new HttpParams()
        .set('test', test),
      reportProgress: false
    };
    return this.http.get(environment.apiUrl + 'test/bookmarks/', options)
  }

  
  dummyTestCreate(test):Observable<any> {
    return this.http.post(environment.apiUrl + 'admin/dummy_test/', test)
  }

  getTesterDetials(test_id):Observable<any> {
    return this.http.post(environment.apiUrl + 'admin/test_tester_correlation/', test_id)
  }

  // Tester  dummy dest api strat serveices

  // changeMessage(currentMessage) {
  //   this.testCreataData.next(currentMessage);
  // }
  // get All Create Test 
  
  getCreateTest(): Observable<any> {
    // const options = {
    //   headers: new HttpHeaders({
    //     Authorization: "Token " + this._authServices.getToken()
    //   }),
    //   params: new HttpParams(),
    //   reportProgress: false
    // };
    return this.http.get(environment.apiUrl + "admin/dummy_test/");
  }
  // Get Tests Lis by TestID
  deleteTest(id): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        Authorization: "Token " + this._authServices.getAuthorizationToken()
      }),
      params: new HttpParams()
        .set('id', id),
      reportProgress: false
    };
    return this.http.delete(environment.apiUrl + "admin/dummy_test/",options);
  }

  
  gelAllProjectList(): Observable<any> {
    // const options = {
    //   headers: new HttpHeaders({
    //     Authorization: "Token " + this._authServices.getToken()
    //   }),
    //   params: new HttpParams(),
    //   reportProgress: false
    // };
    return this.http.get(environment.apiUrl + "test/project_list/");
  }


  getTestById(id: number): Observable<any> {
    // const options = {
    //   headers: new HttpHeaders({
    //     Authorization: "Token " + this._authServices.getToken()
    //   }),
    //   params: new HttpParams(),
    //   reportProgress: false
    // };
    return this.http.get(environment.apiUrl +"test/test/"+id,);
  }

  // updateTest(test):Observable<any>{
  //   const options = {
  //     headers: new HttpHeaders({
  //       Authorization: "Token " + this._authServices.getToken()
  //     }),
  //     params: new HttpParams()
  //     .set('id',localStorage.getItem('testId')),
  //     reportProgress: false
  //   };

  //   return this.http.put(environment.apiUrl + 'test/test/',test,options)
  // }

  //  Delete Api Call 

  // deleteTest(id):Observable<any>{
  //   const options = {
  //     headers: new HttpHeaders({
  //       Authorization: "Token " + this._authServices.getToken()
  //     }),
  //     params: new HttpParams()
  //     .set('id',id),
  //     reportProgress: false
  //   };

  //   return this.http.delete(environment.apiUrl + 'test/test',options,)    

  // }

  // This is FoproArchieve Teste Api

  // archieveTest(id):Observable<any>{
  //   const options = {
  //     headers: new HttpHeaders({
  //       Authorization: "Token " + this._authServices.getToken()
  //     }),
  //     params: new HttpParams()
  //     .set('id',id),
  //     reportProgress: false
  //   };

  //   return this.http.get(environment.apiUrl + 'test/test_archive/',options,)    
  // }

  // cancelTest(id):Observable<any>{
  //   const options = {
  //     headers: new HttpHeaders({
  //       Authorization: "Token " + this._authServices.getToken()
  //     }),
  //     params: new HttpParams()
  //     .set('id',id),
  //     reportProgress: false
  //   };

  //   return this.http.get(environment.apiUrl + 'test/test_cancel/',options)    
  // }

  
  // testerCount(testerCount:TesterCount):Observable<any>{
  //   const options = {
  //     headers: new HttpHeaders({
  //       Authorization: "Token " + this._authServices.getToken()
  //     }),
  //     params: new HttpParams(),
  //     reportProgress: false
  //   };
  //   return this.http.post(environment.apiUrl + 'test/video_setup/',testerCount,options)

  // }

  // getNewVideo(id){
  //   const options = {
  //     headers: new HttpHeaders({
  //       Authorization: "Token " + this._authServices.getToken()
  //     }),
  //     params: new HttpParams()
  //       .set('id', id),
  //     reportProgress: false
  //   };
  //   return this.http.get(environment.apiUrl + 'test/new_video/',options )
  // }

  // getReactiveTest(id){
  //   const options = {
  //     headers: new HttpHeaders({
  //       Authorization: "Token " + this._authServices.getToken()
  //     }),
  //     params: new HttpParams()
  //       .set('id', id),
  //     reportProgress: false
  //   };
  //   return this.http.get(environment.apiUrl + 'test/test_reactive/',options )
  // }

  // project all test 

  // getProjectRelatededList(project_id){
  //   const options = {
  //     headers: new HttpHeaders({
  //       Authorization: "Token " + this._authServices.getToken()
  //     }),
  //     params: new HttpParams()
  //       .set('project_id', project_id),
  //     reportProgress: false
  //   };
  //   return this.http.get(environment.apiUrl + 'test/get_test_by_project/',options )
  // }

  templateManagement(){
    return this.http.get(environment.apiUrl + 'admin/template_management/')
  }

  getTemplateData(){
    return this.http.get(environment.apiUrl + 'admin/get_template_data/')
  }


  addTemplate(parms):Observable<any>{
      return this.http.post(environment.apiUrl + 'admin/template_management/',parms)
    }

    deleteTemplate(id):Observable<any>{
      const options = {
            headers: new HttpHeaders({
              Authorization: "Token " + this._apiSer.getToken()
            }),
            params: new HttpParams()
            .set('id',id),
            reportProgress: false
          };
      return this.http.delete(environment.apiUrl + 'admin/template_management/',options)
    }
  // End of dummy test api services

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }



}
