import { Component, NgZone } from "@angular/core";
import { Router, NavigationEnd, NavigationStart } from "@angular/router";
import { BnNgIdleService } from "bn-ng-idle";


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  login: boolean = false;
  showHead: boolean;
  constructor(
    private zone: NgZone,
    private router: Router,
    private bnIdle: BnNgIdleService
  ) {
    this.bnIdle.startWatching(20000).subscribe((res) => {
      if (res) {
        this.router.navigate(["/login"]);
        alert("session exipred");
        
        localStorage.removeItem("token");
        localStorage.clear();
      }
    });
    router.events.forEach((event) => {
    
      if (event instanceof NavigationEnd) {
        if (
          [
            "/",
            "/login",
            // '/client/login',
            // '/client/register',
            // '/tester/register'
          ].includes(event["url"])
        ) {
          this.showHead = false;
        
        } else {
         
          this.showHead = true;
       
        }
      }
    });
  }
}
