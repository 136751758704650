import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { Notification } from 'src/app/core/models/notification';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { appApiResources } from './api.constant';

@Injectable({
  providedIn: 'root'
})
export class NotificationsMangementService {

  constructor(private http:HttpClient, private _authSer:AuthService) { }


  sendNotificationsv(notifiy:Notification):Observable<any>{
    return this.http
      .post(appApiResources.adminFstspringCoupon, notifiy)
      .pipe(catchError(this.handleError));
  }

  getAllCoupon():Observable<any>{
    return this.http
      .get(appApiResources.adminFstspringCoupon)
      .pipe(catchError(this.handleError));
  }

  deleteCoupon(id){
    const options = {
      headers: new HttpHeaders({
        Authorization: "Token " + this._authSer.getAuthorizationToken()
      }),
      params: new HttpParams()
        .set('id', id),
      reportProgress: false
    };
    return this.http
  .delete(appApiResources.adminFstspringCoupon,options)
  .pipe(catchError(this.handleError));
  }


  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }
}
