import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { PaypalPaymentService } from "src/app/shared/services/paypal-payment.service";

@Component({
  selector: "app-payment-failed",
  templateUrl: "./payment-failed.component.html",
  styleUrls: ["./payment-failed.component.css"],
})
export class PaymentFailedComponent implements OnInit {
  pushDataInList: any[] = [];
  displayedColumns: any[] = [
    "id",
    "email",
    "schedule_date",
    "receiver_paypal_id",
    "payout_status",
    "view",
  ];
  isDetailsPayment: boolean = false;
  paymentDetails: any[];
  constructor(
    private router: Router,
    private _paymentServ: PaypalPaymentService,
    private _router: Router
  ) {}

  ngOnInit() {
    this.pushDataInList = [];
    this.paymentDetails = [];
    this.getUnSuccessfullPayment();
  }

  getUnSuccessfullPayment() {
    let flag = "unsuccessful";
    this._paymentServ.getPaymentList(flag).subscribe((res) => {
      this.pushDataInList.push(this.displayedColumns);
      this.pushDataInList.push(res["data"]);
    });
  }

  reRenderList(event) {
    if (event) {
      this.getUnSuccessfullPayment();
    }
  }

  getSingleInfo(event) {
    this._router.navigate(["payment/details"], { state: { details: event,header:'Tester UnSuccessfull Payment Details' } });
  }

  backTo() {
    this.router.navigate(["payment"]);
  }
}
