import { Component, OnInit, ViewChild } from "@angular/core";
import { UserManagementService } from "src/app/shared/services/user-management.service";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { TokenParms } from "src/app/core/models/token-parms";
import Swal from "sweetalert2";
import { PasswordStrengthValidator } from "src/app/constants/validators";

import {
  MatPaginator,
  MatTableDataSource,
  MatTabChangeEvent,
  MatSort,
} from "@angular/material";

export interface PeriodicElement {
  username: string;
  date_joined: number;
  id: number;
  email: string;
  symbol: any;
  delete: any;
}

var ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: "app-all-user",
  templateUrl: "./all-user.component.html",
  styleUrls: ["./all-user.component.css"],
})
export class AllUserComponent implements OnInit {
  showUserDetails: boolean = false;
  userList: any;
  noDataAvailable: boolean = false;
  error: any;
  allUser: [] = [];
  regForm: FormGroup;
  massage: string;
  registerResMsg: string;
  userRoleType;
  errMsg = [];
  searchText: string;
  registerResEmailMsg: any;
  hideSuccessMessage: boolean = false;
  urlType: string;
  clientSigup = false;
  username: any;
  userUploadTest: [];
  userTestList: [];
  userID: any;
  fieldTextType: boolean;
  allSortedUser = [];
  Error = false;
  submitted: boolean = false;
  passwordIsValid = false;

  displayedColumns: string[] = [
    "id",
    "username",
    "date_joined",
    "email",
    "symbol",
    "delete",
  ];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  useremail: any;

  @ViewChild(MatPaginator, { static: false }) set content(
    paginator: MatPaginator
  ) {
    this.dataSource.paginator = paginator;
  }

  @ViewChild(MatSort, { static: false }) set conten1t(sort: MatSort) {
    this.dataSource.sort = sort;
  }
  allList = [];

  // @ViewChild(FormGroupDirective ,{static:false}) formGroupDirective: FormGroupDirective;

  constructor(
    private _usersServices: UserManagementService,
    private formbuilder: FormBuilder
  ) {
    this.allSortedUser = this.allUser.slice();
  }

  ngOnInit() {
    this.showUserDetails = true;
    // this._usersServices.getAllUsersList().subscribe(res => {
    //   this.dataSource = new MatTableDataSource<PeriodicElement>(res.data.user_details)
    //   this.dataSource.paginator = this.paginator;
    //   this.dataSource.sort = this.sort;

    //   this.allList = res.data.user_details;
    //   this.userUploadTest = res.data.user_uploaded_test;

    // })
    this.allUserList();
    // this.dataSource.paginator = this.paginator;

    this.setRegFormState();
    this.userRoleType = this._usersServices.userRoleType;

    this._usersServices.role.subscribe((role) => {});
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  passwordValid(event) {
    this.passwordIsValid = event;
  }

  setRegFormState(): void {
    this.regForm = this.formbuilder.group({
      // username: ['', [Validators.required,Validators.minLength(6)]],
      // password2: ['', [Validators.required]],
      email: [
        "",
        [
          Validators.required,
          Validators.email,
          Validators.pattern("^[A-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ],
      ],
      password: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(40),
          PasswordStrengthValidator,
        ]),
      ],
      roletype: [""],
      checkMarks: [true],
    });
  }

  onSignUp() {
    this.submitted = true;

    if (this.regForm.invalid) {
      return;
    }
    this.submitted = false;
    let reg = this.regForm.value;
    localStorage.setItem("role", this.urlType);
    if (!window.navigator.onLine) {
      alert("Server is not responding");
    } else {
      this.reg(reg);
      this.regForm.reset();
    }
    // this.regForm.markAsTouched();
  }

  get f() {
    return this.regForm.controls;
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  // Register the Tester And Client

  reg(reg: TokenParms) {
    this._usersServices.register(reg).subscribe(
      (employeeUser) => {
        var succ = employeeUser;
        if (succ.message.email) {
          this.registerResEmailMsg = employeeUser.message.email;
          alert(succ.message.email);
        } else {
          this.registerResMsg = employeeUser.message;
          alert(succ.message);
        }

        if (succ) {
          this.regForm.reset();
          localStorage.setItem("reg", JSON.stringify(succ));

          // this.router.navigate(['login']);
        } else {
          this.Error = true;
          this.massage = "User ID/Password Wrong";
        }
      },
      (err) => {
        this.errMsg = err;
      }
    );
  }

  allUserList() {
    this._usersServices.getAllUsersList().subscribe(
      (res) => {
        this.dataSource = new MatTableDataSource<PeriodicElement>(
          res.data.user_details
        );
        // this.dataSource.paginator = this.paginator;
        // this.dataSource.sort = this.sort;
        this.allList = res.data.user_details;
        this.allUser = res.data.user_details;
        this.allSortedUser = this.allUser;

        this.userUploadTest = res.data.user_uploaded_test;
      },
      (error) => (this.error = error)
    );
  }

  getBadgeLabel(statusCode) {
    let labelText = "";
    let className = "";
    let statusBtn = "";
    let editTest = "";
    let btnHide = "";
    switch (statusCode) {
      case 0:
        labelText = "Running";
        className = "badge badge-info";
        statusBtn = "Get videos";
        editTest = "";
        btnHide = "none";
        break;
      case 1:
        labelText = "pending";
        className = "badge badge-warning";
        statusBtn = "Get videos";
        editTest = "Edit Test";
        btnHide = "block";
        break;
      case 2:
        labelText = "Draft";
        className = "badge badge-warning";
        statusBtn = "Get videos";
        editTest = "Edit Test";
        btnHide = "none";
        break;
      case 6:
        labelText = "CANCELLED";
        className = "badge badge-danger";
        statusBtn = "Reactive Test";
        editTest = "Edit Test";
        btnHide = "block";
        break;
      case 3:
        labelText = "FINISHED";
        className = "badge badge-success";
        statusBtn = "Get new video";
        editTest = "";
        btnHide = "none";
        break;
      case 7:
        labelText = "CONFIRMATION REQUIRED";
        className = "draft red";
        statusBtn = "Reniew video";
        editTest = "Edit Test";
        btnHide = "block";
        break;
      case 5:
        labelText = "Archive";
        className = "badge badge-warning";
        statusBtn = "Get videos";
        editTest = "Edit Test";
        btnHide = "block";
        break;
      default:
        break;
    }
    return {
      labelText,
      className,
      statusBtn,
      editTest,
      btnHide,
    };
  }

  viewDataID(userID, email) {
    this.userTestList = [];

    this.useremail = email;
    this.userID = userID;
    this.allUser.filter((res) => {
      if (res["id"] == userID) {
        this.showUserDetails = false;
        this.username = res["username"];
      }
    });

    this.userUploadTest.filter((res) => {
      if (res["user_id"] == this.userID) {
        this.userTestList.push(res);
      }
      if (this.userTestList.length <= 0) {
        this.noDataAvailable = false;
      } else {
        this.noDataAvailable = true;
      }
    });
  }

  viewAsUser() {
    let adminToken = localStorage.getItem("token");
    let testeremail = this.useremail;
    // window.open('http://localhost:4200/admin-login?adminToken='+ adminToken + '&email=' + testeremail,"_blank")

    window.open(
      "https://app.userpeek.io/-login?adminToken=" +
        adminToken +
        "&email=" +
        testeremail,
      "_blank"
    );
  }

  backProfile() {
    this.showUserDetails = true;
  }

  deleteUeser(email) {
    // confirm("Are you want to delete this user " + email)
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this email " + email,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        this._usersServices.deleteUser(email).subscribe((res) => {
          this.allUserList();
        });
        Swal.fire(
          "Deleted!",
          "Your " + email + " has been deleted.",
          "success"
        );
      }
    });
  }

  // data table

  // sortData(sort: Sort) {
  //   const data = this.allUser.slice();
  //   if (!sort.active || sort.direction === '') {
  //     this.allSortedUser = data;
  //     return;
  //   }

  //   this.allSortedUser = data.sort((a, b) => {
  //     const isAsc = sort.direction === 'asc';
  //     switch (sort.active) {
  //       case 'id': return compare(a['id'], b['id'], isAsc);
  //       case 'Username': return compare(a['username'], b['username'], isAsc);
  //       case 'Created': return compare(a['date_joined'], b['date_joined'], isAsc);
  //       case 'Email': return compare(a['email'], b['email'], isAsc);

  //       default: return 0;
  //     }
  //   });
  // }
}

// function compare(a: number | string, b: number | string, isAsc: boolean) {
//   return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
// }
