import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TestsComponent } from './tests/tests.component';
import { TestedTestComponent } from './tested-test/tested-test.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule,FormsModule } from "@angular/forms";
import { NgxSpinnerModule } from "ngx-spinner"; 
import { DataTableModule } from 'ng-angular8-datatable';
import { MatPaginatorModule, MatProgressBarModule, MatProgressSpinnerModule, MatSnackBarModule, MatSortModule, MatTableModule, MatTabsModule, MatToolbarModule, MatTooltipModule, MatTreeModule, MatRadioModule, MatSelectModule, MatSidenavModule, MatSliderModule, MatSlideToggleModule, MatIconModule, MatFormFieldModule, MatInputModule, MatButtonModule } from '@angular/material';
import { TestedVideoComponent } from './tested-video/tested-video.component';
import { CoreModule } from 'src/app/core/core.module';




@NgModule({
  declarations: [TestsComponent, TestedTestComponent, TestedVideoComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    CoreModule,
    NgxSpinnerModule,
    DataTableModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule

  ]
})
export class TestsModule { }
