import { Component, OnInit, ViewChild } from "@angular/core";
import { environment } from "src/environments/environment";
import {
  Validators,
  FormGroup,
  FormBuilder,
  FormControl,
} from "@angular/forms";
import { TokenParms } from "src/app/core/models/token-parms";
import { TestersService } from "src/app/shared/services/testers.service";
import Swal from "sweetalert2";
import { PasswordStrengthValidator } from "src/app/constants/validators";

import {
  Sort,
  MatTableDataSource,
  MatPaginator,
  MatSort,
} from "@angular/material";
import { TesterProfile } from "src/app/core/models/tester_profile";

export interface PeriodicElement {
  full_name: string;
  joined_date: number;
  id: number;
  email: string;
  symbol: any;
  delete: any;
}

var ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: "app-tester",
  templateUrl: "./tester.component.html",
  styleUrls: ["./tester.component.css"],
})
export class TesterComponent implements OnInit {
  EnvApiUrl : string = environment.apiUrl;
  userList: any;
  error: any;
  allTester: [] = [];
  regForm: FormGroup;
  massage: string;
  registerResMsg: string;
  userRoleType;
  errMsg = [];
  Error = false;
  submitted: boolean = false;
  passwordIsValid = false;
  // @ViewChild(FormGroupDirective ,{static:false}) formGroupDirective: FormGroupDirective;
  registerResEmailMsg: any;
  hideSuccessMessage: boolean = false;
  urlType: string;
  clientSigup = false;
  username: any;
  userUploadTest: [];
  userTestList: [];
  userID: any;
  showUserDetails: boolean = false;
  date: any;
  language: any;
  country: any;
  gender: any;
  userId: any;
  image: any;
  fieldTextType: boolean;
  searchText: string;
  emailTester: any;
  allSortedTester = [];
  pageLength: number = 0;
  pageIndex: number = 0;
  displayedColumns: string[] = [
    "id",
    "full_name",
    "joined_date",
    "email",
    "status",
    "symbol",
    "delete",
  ];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  totalTestedtestCount: any;
  averageRating: any;
  statusShow: any;
  unban: boolean = false;
  street: any;
  city: any;
  zipcode: any;
  testerDeatils: any;

  @ViewChild(MatPaginator, { static: false }) set content(
    paginator: MatPaginator
  ) {
    this.dataSource.paginator = paginator;
  }

  @ViewChild(MatSort, { static: false }) set conten1t(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  constructor(
    private _testerService: TestersService,
    private formbuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.showUserDetails = true;
    this.allTesterList();
    this.setRegFormState();
    // this.userRoleType = this._testerService.userRoleType; 

    // this._testerService.role.subscribe((role) => {});
  }

  passwordValid(event) {
    this.passwordIsValid = event;
  }

  formatTimestamp(date){
    const date1 = new Date(date);
    let date2 = date1.toLocaleString('en-GB', {year: 'numeric', day: 'numeric', month: 'numeric',  hour: '2-digit', minute: '2-digit', hour12: true}); 
    return date2;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  setRegFormState(): void {
    this.regForm = this.formbuilder.group({
  
      email: [
        "",
        [
          Validators.required,
          Validators.email,
          Validators.pattern("^[A-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ],
      ],
      password: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(40),
          PasswordStrengthValidator,
        ]),
      ],
      roletype: [""],
      extra: new FormGroup({
        language: new FormControl("English"),
        country: new FormControl("Germany"),
        full_name: new FormControl(""),
        year: new FormControl("2000"),
        gender: new FormControl("Male"),
        //dators.required, <any>Validators.minLength(4)]),
        image: new FormControl(null),
        device_available_to_test: this.formbuilder.array(["Macintosh PC"]),
        // tokenId: new FormControl(),
      }),
      checkMarks: [true],
    });
  }

  onSignUp() {
    this.submitted = true;

    if (this.regForm.invalid) {
      console.log('hi');
    }
    this.submitted = false;
    let reg = this.regForm.value;
    localStorage.setItem("role", this.urlType);
    this.reg(reg);
    this.regForm.reset();

    // this.regForm.markAsTouched();
  }

  get f() {
    return this.regForm.controls;
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  unbanTester(isBan) {
    let data = {
      email: this.emailTester,
      is_banned: isBan,
    };
    if (isBan == true) {
      var msg = "You want to Shadow ban this account !";
      var btnmsg = "Shadow ban";
    } else {
      var msg = "You want to Unban this account !";
      var btnmsg = "unban";
    }
    Swal.fire({
      title: "Are you sure?",
      text: msg,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, " + btnmsg + " it!",
    }).then((result) => {
      if (result.value) {
        this._testerService.unbanTester(data).subscribe(
          (res) => {
            if (res["status"] == 200) {
              if (isBan == true) {
                this.unban = true;

                Swal.fire(
                  "Shadow ban !",
                  "this" +
                    this.emailTester +
                    " account  has been not recieved any new test.",
                  "success"
                );
              } else {
                this.unban = false;

                Swal.fire(
                  "Unban !",
                  "this" +
                    this.emailTester +
                    " account  has been  recieved  new test.",
                  "success"
                );
              }
            } else {
              Swal.fire("Error!", res["message"], "error");
            }
          },
          (err) => {
            Swal.fire("Error!", err, "error");
          }
        );
      }
    });
  }

  // Register the Tester And Client

  reg(reg: TesterProfile) {
    this._testerService.register(reg).subscribe(
      (employeeUser) => {
        var succ = employeeUser;
        if (succ.message.email) {
          this.registerResEmailMsg = employeeUser.message.email;
          alert(succ.message.email);
        } else {
          this.registerResMsg = employeeUser.message;
          alert(succ.message);
        }
        if (succ) {
          this.regForm.reset();
          localStorage.setItem("reg", JSON.stringify(succ));
          // this.router.navigate(['login']);
        } else {
          this.Error = true;
          this.massage = "User ID/Password Wrong";
        }
      },
      (err) => {
        this.errMsg = err;
      }
    );
  }

  allTesterList() {
    this.userList = this._testerService.getAllTesterList().subscribe(
      (res) => {
        this.dataSource = new MatTableDataSource<PeriodicElement>(
          res.data.tester_details
        );

        this.allTester = res.data.tester_details;
        this.allSortedTester = this.allTester;
        this.pageIndex = 0;
        this.pageLength = this.allTester.length;

        // this.userUploadTest = res.data.user_uploaded_test
      },
      (error) => (this.error = error)
    );
  }

  getBadgeLabel(statusCode) {
    let labelText = "";
    let className = "";
    let statusBtn = "";
    let editTest = "";
    let btnHide = "";
    switch (statusCode) {
      case 0:
        labelText = "Running";
        className = "badge badge-info";
        statusBtn = "Get videos";
        editTest = "";
        btnHide = "none";
        break;
      case 1:
        labelText = "pending";
        className = "badge badge-warning";
        statusBtn = "Get videos";
        editTest = "Edit Test";
        btnHide = "block";
        break;
      case 2:
        labelText = "Draft";
        className = "badge badge-warning";
        statusBtn = "Get videos";
        editTest = "Edit Test";
        btnHide = "none";
        break;
      case 6:
        labelText = "CANCELLED";
        className = "badge badge-danger";
        statusBtn = "Reactive Test";
        editTest = "Edit Test";
        btnHide = "block";
        break;
      case 3:
        labelText = "FINISHED";
        className = "badge badge-success";
        statusBtn = "Get new video";
        editTest = "";
        btnHide = "none";
        break;
      case 7:
        labelText = "CONFIRMATION REQUIRED";
        className = "draft red";
        statusBtn = "Reniew video";
        editTest = "Edit Test";
        btnHide = "block";
        break;
      case 5:
        labelText = "Archive";
        className = "badge badge-warning";
        statusBtn = "Get videos";
        editTest = "Edit Test";
        btnHide = "block";
        break;
      default:
        break;
    }
    return {
      labelText,
      className,
      statusBtn,
      editTest,
      btnHide,
    };
  }

  backProfile() {
    this.showUserDetails = true;
    this.allTesterList();
  }

  viewAsTester() {
    let adminToken = localStorage.getItem("token");
    let testeremail = this.emailTester;
    window.open(
      "https://tester.userpeek.io/admin-login?adminToken=" +
        adminToken +
        "&email=" +
        testeremail,
      "_blank"
    );
  }

  viewDataID(userID) {
    this.allTesterList();
    this.getUnban();
    this.userTestList = [];

    this.userID = userID;

    this.allTester.filter((res) => {
      if (res["id"] == this.userID) {
        
        this.testerDeatils = res;
        this.date = res["joined_date"];
        this.userId = res["user_id"];
        this.language = res["language"];
        this.image = res["image"];
        this.emailTester = res["email"];
        this.showUserDetails = false;
      }
    });
    
    this._testerService.getUnban(this.emailTester).subscribe((res) => {
      if (res["data"][0].is_banned == true) {
        this.unban = true;
      } else {
        this.unban = false;
      }
    });
  }

  getUnban() {}


  deleteUeser(email) {
    
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this email " + email,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        this._testerService.deletetester(email).subscribe((res) => {
          this.allTesterList();
        });
        Swal.fire(
          "Deleted!",
          "Your " + email + " has been deleted.",
          "success"
        );
      }
    });
  }
  // data table

  sortData(sort: Sort) {
    const data = this.allTester.slice();
    if (!sort.active || sort.direction === "") {
      this.allSortedTester = data;
      return;
    }

    this.allSortedTester = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "id":
          return compare(a["id"], b["id"], isAsc);
        case "Username":
          return compare(a["username"], b["username"], isAsc);
        case "Created":
          return compare(a["date_joined"], b["date_joined"], isAsc);
        case "Email":
          return compare(a["email"], b["email"], isAsc);
      
        default:
          return 0;
      }
    });
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
