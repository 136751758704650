import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { PaypalPaymentService } from "src/app/shared/services/paypal-payment.service";

import { TestsService } from "src/app/shared/services/tests.service";

@Component({
  selector: "app-pending-payment",
  templateUrl: "./pending-payment.component.html",
  styleUrls: ["./pending-payment.component.css"],
})
export class PendingPaymentComponent implements OnInit {
  pushDataInList: any[] = [];
  displayedColumns: any[] = [
    "id",
    "email",
    "test_id",
    "video_id",
    "amount",
    "schedule_date",
    "rejectPayment",
  ];
  constructor(
    private router: Router,
    private _tests: TestsService,
    private _paymentServ: PaypalPaymentService
  ) {
    console.log("pending compo load");
  }

  ngOnInit() {
    this.pushDataInList = [];
    this.getAwaitingPayment();
  }

  getAwaitingPayment() {
    let flag = "awaiting";
    this._paymentServ.getPaymentList(flag).subscribe((res) => {
      this.pushDataInList.push(this.displayedColumns);
      this.pushDataInList.push(res["data"]);
    });
  }

  reRenderList(event) {
    if (event) {
      this.getAwaitingPayment();
    }
  }

  backTo() {
    this.router.navigate(["payment"]);
  }

  payToAllPendingTester() {
    this._paymentServ.startPendingPayout().subscribe((res) => {
      if (res["status"] == 200) {
        alert("payout Complete");
      }
    });
  }
}
