import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SplitWordsPipe } from './pipes/split-words.pipe';
import { ToggleDirective } from './directives/toggle.directive';
import { UsernameShowPipe } from './pipes/username-show.pipe';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { TestFilterPipe } from './pipes/test-filter.pipe';
import { SearchProjectPipe } from './pipes/search-project.pipe';
import { LoaderComponent } from './loader/loader.component';
import { NgxSpinnerModule } from 'ngx-spinner';
// import { SidebarComponent } from './sidebar/sidebar.component';
// import { HeaderTesterComponent } from './header-tester/header-tester.component';







@NgModule({
  declarations: [
    SplitWordsPipe,
    ToggleDirective,
    UsernameShowPipe,
    SearchFilterPipe,
    TestFilterPipe,
    SearchProjectPipe,
    LoaderComponent,
  
    // HeaderTesterComponent,
 
  
  ],
  imports: [
    CommonModule,
    NgxSpinnerModule
    
  ],
  exports:[
    SplitWordsPipe,
    UsernameShowPipe,
    SearchFilterPipe,
    TestFilterPipe,
    SearchProjectPipe,
    LoaderComponent
    // SidebarComponent
    // HeaderTesterComponent
   
  ]
  

})
export class SharedModule { }
