import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, FormControl, Validators } from "@angular/forms";
import { FaqService } from "src/app/shared/services/faq.service";
import { Subscription } from "rxjs";
import Swal from "sweetalert2";

@Component({
  selector: "app-faq-management",
  templateUrl: "./faq-management.component.html",
  styleUrls: ["./faq-management.component.css"],
})
export class FaqManagementComponent implements OnInit {
  // faq: FormGroup;
  // allFaqList: any;
  // allFaq: Subscription;
  // documents: any;
  // showId: any;
  // hideFaq: boolean = true;
  // faqUpdate: FormGroup;

  PagesForm: FormGroup;
  postStaticData:boolean = false
  private formSubmitAttempt: boolean;
  message :string = null;
  pageData :any;
  updateData: FormGroup;
  updateId: any;
  constructor(private api : FaqService, private fb: FormBuilder) { }

  // constructor(private _fb: FormBuilder, private _faqService: FaqService) {}

  ngOnInit() {
    this.createForm();
    this.setPageData();
    // this.getAllFaq();

    // this.faq = this._fb.group({
    //   question: [""],
    //   answer: [""],
    // });

    this.updateData = this.fb.group({
      about_us: [""],
      privacy_policy: [""],
      terms_conditions:[""]
    });

    this.api.getStaticPage().subscribe(data=>{
      this.pageData =  data['data'];
      this.updateId = data['data'][0].id
      if(data['data'].length<=0){
        this.postStaticData = false
      }else{
        this.postStaticData = true
      }
      this.updateData.patchValue({
       about_us:this.pageData[0].about_us,
       privacy_policy:this.pageData[0].privacy_policy,
       terms_conditions:this.pageData[0].terms_conditions
      })
      
  })

    // this._faqService.getAllFaq().subscribe(res=>{
    
    //   this.allFaqList = res.data
    //   this.allFaqList.filter(res=>{
    //     // if(this.showId == res.id){
    //       this.faqUpdate.patchValue({
    //         question:res.question,
    //         answer:res.answer
    //       })

    //   })

    
    // })
  }


  private createForm() {
    this.PagesForm= new FormGroup({
     about_us: new FormControl('',[Validators.required]),
     privacy_policy: new FormControl('',[Validators.required]),
     terms_conditions :new FormControl('',[Validators.required]),
    });
  }



  allPages(){   
    this.formSubmitAttempt = true;
    this.postStaticData = true

   
    if (this.PagesForm.valid) {

         this.api.createPage(this.PagesForm.value).subscribe(resp => {
           this.ngOnInit()
         this.message = "Pages Successfully Saved";
         this.postStaticData = true
         Swal.fire(
          'Good job!',
          'You are add the static content!',
          'success'
        )
         
       });
      }
      else{
         
    }
   }
   
 
   setPageData(){
     this.api.getStaticPage().subscribe(data=>{
      
       this.updateId = data['data'][0].id
       this.pageData =  data['data'];
       this.updateData.patchValue({
        about_us:data['data'].about_us,
        privacy_policy:data['data'].privacy_policy,
        terms_conditions:data['data'].terms_conditions
       })
      
   })
 }

 updateStaticData(){
   this.api.updatecreatePage(this.updateData.value,this.updateId).subscribe(res=>{
    //  alert(res['message'])
    Swal.fire(
      'Updated!',
      'You are update the static content!',
      'success'
    )
   })
 }

  // addFaq() {
  //   this.documents = [
  //     {
  //       question: this.faq.value.question,
  //       answer: this.faq.value.answer,
  //     },
  //   ];

  //   this._faqService.addFaq(this.documents).subscribe((res) => {
  //     this.faq.reset();
  //     this.getAllFaq();
  //     alert("FAQ are added");
  //   });
  // }

  // getAllFaq() {
  //   this.allFaq = this._faqService.getAllFaq().subscribe((res) => {
  //     this.allFaqList = res.data;

  
  //   });
  // }

  // editFaq(id) {
  //   this._faqService.getAllFaq().subscribe((res) => {
 
  //     this.allFaqList = res.data;
  //     this.allFaqList.filter((res) => {
  //       if (this.showId == res.id) {
  //         this.faqUpdate.patchValue({
  //           question: res.question,
  //           answer: res.answer,
  //         });
  //       }
  //     });

  
  //   });
  //   this.showId = id;
  //   if (this.allFaqList.id == id) {
  //     this.hideFaq = true;
  //   }
  // }

  // addFaqUpdate() {
  //   this._faqService
  //     .updateFaq(this.faqUpdate.value, this.showId)
  //     .subscribe((res) => {
  //       this.showId = null;
  //       alert(res.message);
  //       this.getAllFaq();
  //     });
  // }

  // cancelUpdate(id) {
  //   this.showId = null;
  // }

  // removeFaq(id) {
  //   this._faqService.deleteFaq(id).subscribe(res=>{
  //     alert("You are deleted this FAQ")
  //     this.getAllFaq()
      
  //   })
  // }
}
