import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { TestsService } from 'src/app/shared/services/tests.service';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { UserManagementService } from 'src/app/shared/services/user-management.service';

export interface PeriodicElement {
  username: string;
  date_joined: number;
  id: number;
  email: string;
}

var ELEMENT_DATA: PeriodicElement[] = [];


@Component({
  selector: 'app-tested-test',
  templateUrl: './tested-test.component.html',
  styleUrls: ['./tested-test.component.css']
})
export class TestedTestComponent implements OnInit {
  displayedColumns: string[] = ['id', 'username', 'date_joined', 'email'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);


  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  allList=[];

  constructor( private _usersServices: UserManagementService) {
   
  }

  ngOnInit(){
    
    this._usersServices.getAllUsersList().subscribe(res=>{
      this.dataSource = new MatTableDataSource<PeriodicElement>(res.data.user_details)
      this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
      this.allList =res.data.user_details;
      


    })
    
  }

}
