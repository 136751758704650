import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
// import { TestProjectComponent } from './tester-upload-video/test-project/test-project.component';
// import { TestVideoDeatilsComponent } from './tester-upload-video/test-project/test-video-deatils/test-video-deatils.component';
// import { ApiCallComponent } from './api-call/api-call.component';
// import { RegisterComponent } from './auth/register/register.component';
// import { FormUploadComponent } from './tester/form-upload/form-upload.component';
// import { VideoPlayerComponent } from './video-player/video-player.component';
// import { TesterComponent } from './tester/tester.component';
// import { TesterProfileEditComponent } from './tester/tester-profile-edit/tester-profile-edit.component';
// import { TesterProfileComponent } from './tester/tester-profile/tester-profile.component';
import { LoginComponent } from './auth/login/login.component';
import { AuthGuard } from '../auth.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
// import { DashboardTestComponent } from './dashboard-test/dashboard-test.component';
// import { VideosComponent } from '../videos/videos.component';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
// import { PasswordStrengthComponent } from './password-strength/password-strength.component';
import { SharedModule } from '../shared/shared.module';
import { CoreRoutingModule } from "./../core/core-routing.module";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Ng5SliderModule } from 'ng5-slider';
import { SimpleNotificationsModule } from 'angular2-notifications';
// import { DashboardTestEditComponent } from './dashboard-test/dashboard-test-edit/dashboard-test-edit.component';
// import { VideosModule } from '../videos/videos.module';
// import { VideosRoutingModule } from '../videos/videos-routing.module';
import { NgxSpinnerModule } from "ngx-spinner";
import {MatBadgeModule} from '@angular/material/badge';
import {MatCheckboxModule,MatCardModule,MatMenuModule} from '@angular/material';
import { RegisterComponent } from './auth/register/register.component';
import { FaqManagementComponent } from './faq-management/faq-management.component';
import { NotificationManagementComponent } from './notification-management/notification-management.component';
import { VideosComponent } from './videos/videos.component';
// import { JwPaginationComponent } from 'jw-angular-pagination';
import { MatPaginatorModule, MatProgressBarModule, MatProgressSpinnerModule, MatSnackBarModule, MatSortModule, MatTableModule, MatTabsModule, MatToolbarModule, MatTooltipModule, MatTreeModule, MatRadioModule, MatSelectModule, MatSidenavModule, MatSliderModule, MatSlideToggleModule, MatIconModule, MatFormFieldModule, MatInputModule, MatButtonModule } from '@angular/material';
import { VideoInfoComponent } from './videos/video-info/video-info.component';
import { DemoVideosComponent } from './demo-videos/demo-videos.component';
import { DemoTestComponent } from './demo-test/demo-test.component';
import { DemoTestedVideoComponent } from './demo-tested-video/demo-tested-video.component';
import { TemplateManagementComponent } from './template-management/template-management.component';
import { DeclinedTesterListComponent } from './declined-tester-list/declined-tester-list.component';
import { TableFilterComponent } from './table-filter/table-filter.component';


// import { PasswordStrengthComponent } from './password-strength/password-strength.component';

// import { VideosComponent } from '../modules/videos/videos.component';
// import { DashboardTesterComponent } from './dashboard-tester/dashboard-tester.component';
// import { LoginTesterComponent } from './auth/login-tester/login-tester.component';



@NgModule({
  declarations: [
    // JwPaginationComponent,
    // TestProjectComponent,
    // TestVideoDeatilsComponent,
    // ApiCallComponent,
    LoginComponent,
    // RegisterComponent,
    //  FormUploadComponent,
    // VideoPlayerComponent,
    //  TesterComponent,
    //  TesterProfileEditComponent,
    //  TesterProfileComponent,
    // VideosComponent,
    DashboardComponent,
    RegisterComponent,
    FaqManagementComponent,
    NotificationManagementComponent,
    VideosComponent,
    VideoInfoComponent,
    DemoVideosComponent,
    DemoTestComponent,
    DemoTestedVideoComponent,
    TemplateManagementComponent,
    DeclinedTesterListComponent,
    TableFilterComponent,
    // DashboardTestComponent,
    // DashboardTestEditComponent,
    // DashboardTesterComponent,
    // LoginTesterComponent,

  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    PasswordStrengthMeterModule,
    Ng5SliderModule,
    SharedModule,
    BrowserAnimationsModule,
    SimpleNotificationsModule.forRoot(),
    CoreRoutingModule,
    MatBadgeModule,
    MatButtonModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,

    // VideosModule,
    // VideosRoutingModule,
    NgxSpinnerModule
  ],
  exports:[
    TableFilterComponent,
    MatBadgeModule,
    MatButtonModule, MatCheckboxModule,MatToolbarModule,MatInputModule,MatProgressSpinnerModule,MatCardModule,MatMenuModule, MatIconModule
  ],
  providers: [
    AuthGuard
  ],
})
export class CoreModule { }
