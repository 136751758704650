import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';


import { AuthService } from '../shared/services/auth.service';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
      
    constructor(private router : Router) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if(req.headers.get("Content-Type") == "application/json"){
        return next.handle(req.clone());
    }
    if(localStorage.getItem('token')!= null) { 
       const request = req.clone({
          headers: req.headers.set('Authorization','Token '+ localStorage.getItem('token'))
        });
        return next.handle(request).pipe(tap(
            (err: any) => {
              if (err instanceof HttpErrorResponse) {
                
                if (err.status === 401) {
                  this.router.navigate(['login']);
                }
              }
            }
          ));
    }
    else{
        this.router.navigateByUrl('/login');
    }

   
  } 
}