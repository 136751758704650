import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PendingPaymentComponent } from './pending-payment/pending-payment.component';
import { CoreModule } from 'src/app/core/core.module';
import { PaymentComponent } from './payment/payment.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { PaymentFailedComponent } from './payment-failed/payment-failed.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { PaymentDetailsComponent } from './payment-details/payment-details.component';



@NgModule({
  declarations: [PendingPaymentComponent, PaymentComponent, PaymentSuccessComponent, PaymentFailedComponent, PaymentDetailsComponent],
  imports: [
    CommonModule,
    CoreModule,
    RouterModule,
    SharedModule
  ]
})
export class PaymentModule { }
