import { Component, OnInit } from "@angular/core";
import { TestsService } from "src/app/shared/services/tests.service";

@Component({
  selector: "app-videos",
  templateUrl: "./videos.component.html",
  styleUrls: ["./videos.component.css"],
})
export class VideosComponent implements OnInit {
  allTestedList: any;
  pageIndex: number;
  pageLength: any;
  pagedItems: Array<any>;
  data = [];
  constructor(private _tests: TestsService) {}

  ngOnInit() {
    this._tests.getAllTestsList().subscribe((res) => {
      this.data = res.data.tested_test;
      this.pageIndex = 0;
      this.pageLength = this.data.length;
    });
    this.data = Array(1000)
      .fill(0)
      .map((x, i) => ({
        id: i + 1,
        name: `Item Paged ${i + 1}`,
        product: `Product ${i + 1}`,
      }));
  }
  beginPagination(pagedItems: Array<any>) {
    this.pagedItems = pagedItems;
  }
}
