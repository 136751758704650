import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpErrorResponse,
} from "@angular/common/http";
import { AuthService } from "./auth.service";
import { Observable, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { catchError } from "rxjs/operators";
import { ExampleVideo } from "src/app/core/models/example-video-upload";
import { appApiResources } from "./api.constant";

@Injectable({
  providedIn: "root",
})
export class ExampleVideoUploadService {
  constructor(private http: HttpClient, private _authSer: AuthService) {}

  uploadExampleVideo(examplevideo: ExampleVideo): Observable<any> {
    let formData = new FormData();
    formData.append("test_name", examplevideo.test_name),
      formData.append("file", examplevideo.file),
      formData.append("duration", examplevideo.duration),
      formData.append("description", examplevideo.description);
    return this.http
      .post(appApiResources.adminExampleVideo, formData)
      .pipe(catchError(this.handleError));
  }

  getAllExampleVideo(): Observable<any> {
    return this.http
      .get(appApiResources.adminExampleVideo)
      .pipe(catchError(this.handleError));
  }

  deleteExampleVideo(id) {
    const options = {
      headers: new HttpHeaders({
        Authorization: "Token " + this._authSer.getAuthorizationToken(),
      }),
      params: new HttpParams().set("id", id),
      reportProgress: false,
    };
    return this.http
      .delete(appApiResources.adminExampleVideo, options)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }
}
