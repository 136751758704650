import { environment } from "src/environments/environment";


const baseUrl = environment.apiUrl;

function createUrl(actionName:string){
    return `${baseUrl}${actionName}`;
}

export const appApiResources = {

    adminLogin:createUrl('admin/login/'),

    adminGetDashboardData:createUrl("admin/dashboard/"),

    adminDummyTestTesterDetails:createUrl("admin/dummy_test_tester_details/"),

    getApproveRejectTest:createUrl("admin/dummy_test_reject_approve/"),

    adminRejectedTester:createUrl("admin/rejected_tester/"),

    adminExampleVideo:createUrl("admin/example_video/"),

    adminFaqManagement:createUrl("admin/faq_management/"),

    adminStaticPageManagement:createUrl('admin/static_content_management/'),

    adminFstspringCoupon:createUrl("fastspring/coupon"),

    paypalPaymentAllList:createUrl("admin/test_payout_status/"),

    rejectVideoPayout:createUrl('admin/reject_test/'),

    payoutStart:createUrl('payout')

}