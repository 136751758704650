import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ExampleVideoUploadService } from "src/app/shared/services/example-video-upload.service";
import Swal from "sweetalert2";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-demo-videos",
  templateUrl: "./demo-videos.component.html",
  styleUrls: ["./demo-videos.component.css"],
})
export class DemoVideosComponent implements OnInit {
  exampleVideo: FormGroup;
  allExampelVideo: any;
  submitted = false;
  errorMessage: string;
  showVideoDetails: boolean = false;
  showExampleVideo: any;
  totalDuratioVideo: HTMLElement;
  EnvApiUrl : string = environment.apiUrl;

  constructor(
    private _fb: FormBuilder,

    private _exampleSer: ExampleVideoUploadService
  ) {}

  ngOnInit() {
    this.exampleVideo = this._fb.group({
      test_name: ["", Validators.required],
      duration: [""],
      file: ["", Validators.required],
      description: ["", Validators.required],
    });
    this.getExampleVideo();
    var fileInput = document.getElementById("fileInput");
  }

  onFileSelect(event) {
    // this.totalDuratioVideo= document.getElementById('sp');

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.exampleVideo.get("file").setValue(file);
    }
    // this.totalDuratioVideo = document.getElementById('sp');
    var file = event.target.files[0];
    var reader = new FileReader();
    reader.onload = function () {
      var aud: any = new Audio(<string>reader.result);
      aud.onloadedmetadata = function () {
        this.totalDuratioVideo = aud.duration;
        let timeData = this.totalDuratioVideo;
        var mind = timeData % (60 * 60);
        var minutes = Math.floor(mind / 60);
        var secd = mind % 60;
        var seconds = Math.ceil(secd);
        localStorage.setItem("total_duration_example", minutes + ":" + seconds);
      };
    };
    reader.readAsDataURL(file);
  }

  uploadExampleVideo() {
    this.submitted = true;
    if (this.exampleVideo.invalid) {
      return;
    }
    this.submitted = false;

    this.exampleVideo
      .get("duration")
      .patchValue(localStorage.getItem("total_duration_example"));

    if (this.allExampelVideo.length < 3) {
      this._exampleSer.uploadExampleVideo(this.exampleVideo.value).subscribe(
        (res) => {
          if (res.status == 200) {
            this.getExampleVideo();
            this.exampleVideo.reset();
            Swal.fire("Uploaded !", "Example video are uploaded.", "success");
          } else {
            Swal.fire("Error !", res.message, "error");
          }
        },
        (error) => {
          Swal.fire("Bad Request", "Something went wrong.", "error");
        }
      );
    } else {
      Swal.fire(
        "Limit",
        "You can  upload a maximum 3 example video!.",
        "error"
      );
    }
  }

  getExampleVideo() {
    this._exampleSer.getAllExampleVideo().subscribe(
      (res) => {
        if (res.status == 200) {
          this.allExampelVideo = res.data;
          if (res.data.length === 0) {
            this.errorMessage = "No example videos";
          }
        } else {
          Swal.fire("Error !", res.message, "error");
        }
      },
      (error) => {
        Swal.fire("Bad Request", "Something went wrong.", "error");
      }
    );
  }

  ViewExampleVideo(videoId) {
    this.showVideoDetails = true;
    this.allExampelVideo.find((res) => {
      if (res.id == videoId) {
        this.showExampleVideo = res;
      }
    });
  }

  backBtn() {
    this.showVideoDetails = false;
  }

  deleteExampleVideo(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Example Test!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        this._exampleSer.deleteExampleVideo(id).subscribe(
          (res) => {
            if (res["status"] == 200) {
              this.getExampleVideo();
              Swal.fire(
                "delete!",
                "Your example video has  been deleted.",
                "success"
              );
            } else {
              Swal.fire("Error!", res["message"], "error");
            }
          },
          (err) => {
            Swal.fire("Error!", err, "error");
          }
        );
      }
    });
  }
}
