import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'testFilter'
})
export class TestFilterPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
   return value.filter(res=>{
      res.test_status == 1
    });
  }

}
