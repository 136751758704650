import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
  Renderer,
  HostListener,
} from "@angular/core";
import { Router, NavigationEnd, Params, ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";
import { TokenParms } from "src/app/core/models/token-parms";
// import { DashboardClientService } from "../services/dashboard-client.service";
import Swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";
// import { TesterTestedListService } from "../services/tester-tested-list.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
  // host: {
  //   "(document:click)": "onDocumentClicked($event)"
  // }
})
export class HeaderComponent implements OnInit {
  error1: any;
  urlReset: string;
  // [x: string]: any;
  // token_id: TokenParms;
  // error1;
  // status;
  // showHeader: boolean = false;
  // userNameShow: string;
  // isShown: boolean = false;
  // opened: boolean = false;
  // public text;
  // testerShow: boolean = false;
  // private wasInside = false;
  // status1;
  // testerVideoShow;
  // vId;
  // projectNamebyId;

  // @HostListener('document:click', ['$event'])
  // clickout(event) {
  //   if(this.eRef.nativeElement.contains(event.target)) {

 
  //   } else {
  //     this.isShown = this.isShown;
  
  //   }
  // }

  // islogged:boolean=false
  constructor(
    private authSer: AuthService,
    private _router: Router,
    private render: Renderer,
    private _route: ActivatedRoute,
    private eRef: ElementRef ,
    public toastr: ToastrService
    
  ) // private _testedTesterService: TesterTestedListService
  {
    //   if (localStorage.getItem("usertype") === "client") {
    //     this.testerShow = true;
    //   } else {
    //     this.testerShow = false;
  }
  //   // This is for Hide the some header Menu when we go to Create_test link

  //   this._router.events.subscribe((event: any) => {
  //     var testId = JSON.stringify(localStorage.getItem("testId"));

  //     if (event instanceof NavigationEnd) {
  //       if (
  //         event.url === "/create_test" ||
  //         event.url === "/dashboard_tests/edit/" + JSON.parse(testId)
  //       ) {
  //         this.showHeader = true;
  //       } else {
  //         this.showHeader = false;
  //       }
  //     }
  //   });

  //   // this is for videos Headers

  //   this._router.events.subscribe((event: any) => {
  //     if (event instanceof NavigationEnd) {
  //       if (event.url === "/videos/videos_info/" + this.vId) {
  //         this.testerVideoShow = true;
  //         this.showHeader = true;
  //       } else {
  //         this.testerVideoShow = false;
  //         this.showHeader = false;
  //       }
  //     }
  //   });
  // }
  ngOnInit() {
    this.urlReset = this.authSer.url;
  }
  // ngOnInit() {

  //   this.projectNamebyId = localStorage.getItem("projectName");

  //   this._testedTesterService.testedId$.subscribe(res => {
  //     let data = res;
 
  //     localStorage.setItem("vidId", data);
  //     if (res != null) {
  //       this.vId = res;
  //     } else {
  //       this.vId = localStorage.getItem("vidId");
  //     }
  //   });

  //   this._testedTesterService.testedIdProjectName$.subscribe(projectName => {
  //     let proName = projectName;
 
  //   });

  //   // this._route.params.subscribe((params: Params) => {
  //   //   this.id = +params["id"];
  //   //   this.test = params["id"] != null;
  //   //   localStorage.setItem("videosId", this.id);
  
  //   // })

  //   this.userNameShow = localStorage.getItem("data");
  //   var usertype = localStorage.getItem("usertype");
  //   // if (localStorage.getItem("usertype") === "tester") {
  //   //   this.testerShow = true;
  //   // } else {
  //   //   this.testerShow = false;
  //   // }

  //   // this._dashboardServices.apiData$.subscribe(
  //   //   res=>{
  //   //     if(res.usertype == 'tester')
  //   //       this.testerShow = true

  //   //   }
  //   // )

  //   // this.islogged = this.authSer.loggedIn()
  // }

  // @HostListener("click")
  // clickInside() {
  //   // this.text = "clicked inside";
  //   this.wasInside = true;
  // }

  // @HostListener("document:click")
  // clickout() {
  //   if (!this.wasInside) {
  //     this.isShown = false;
  //     // this.text = "clicked outside";
  //   }
  //   this.wasInside = false;
  // }

  // toggleShow() {
  //   this.isShown = !this.isShown;
  // }
  // onDocumentClicked(ev) {
  //   this.isShown = this.isShown;
  
  // }

  // // logout() {
  // //   localStorage.removeItem('token_id')
  // //   localStorage.clear()
  // //   this.authSer.logout()
  // //     .subscribe(res => {
  // //       this._router.navigate(['/']),
  // //         this.error1 = res
  // //       err => {
  
  // //       }
  // //     })
  // // }

  // logout() {
  //   this.authSer.logout().subscribe(res => {
  //     localStorage.removeItem("token_id");
  //     localStorage.clear();
  //     const Toast = Swal.mixin({
  //       toast: true,
  //       position: "top-end",
  //       showConfirmButton: false,
  //       timer: 3000,
  //       timerProgressBar: true,
  //       onOpen: toast => {
  //         toast.addEventListener("mouseenter", Swal.stopTimer);
  //         toast.addEventListener("mouseleave", Swal.resumeTimer);
  //       }
  //     });

  //     Toast.fire({
  //       icon: "success",
  //       title: "Logout successfully",
  //       background: "#1200ff"
  //     });
  //     this._router.navigate(["/"]), (this.error1 = res);
  //     error => console.log("this was an err", error);
  //   });
  // }

  logout() {
    // if(localStorage.getItem('role')=='client'){

    Swal.fire({
      title: "Are you sure?",
      text: "You want to logout this account!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Logout!",
    }).then((result) => {
      if (result.value) {
        this.authSer.logoutAdmin().subscribe((res) => {
          localStorage.removeItem("token_id");
          localStorage.clear();
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          // Toast.fire({
          //   icon: "success",
          //   title: "Logout successful",
          // });
          this.showSuccessToster();
          this._router.navigate(["/"]), (this.error1 = res);
          (error) => console.log("this was an err", error);
        });
      }
    });
  }

  showSuccessToster() {
    this.toastr.success("Logout successful!", "Success!");
  }
}
