import { Component, OnInit, Renderer2 } from "@angular/core";
import { Validators, FormBuilder, FormGroup } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";

import { AuthService } from "src/app/shared/services/auth.service";
import { TokenParms } from "src/app/core/models/token-parms";
import { Constant } from "./../../../constants/constant";
// import { DashboardClientService } from "src/app/shared/services/dashboard-client.service";
import Swal from "sweetalert2";
import { AlertService } from "src/app/shared/services/alert.service";
import { first } from "rxjs/operators";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  responseError: any;
  urlReset: any;
  fieldTextType: boolean;
  error;
  message;
  loginError;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthService,
    private alertService: AlertService,
    private SpinnerService: NgxSpinnerService,
    public toastr: ToastrService
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(["/"]);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: [
        "",
        [
          Validators.required,
          Validators.email,
          Validators.pattern("^[A-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ],
      ],
      password: ["", Validators.required],
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";
    this.urlReset = this.authenticationService.url;
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  onSubmit() {
    let that = this;
    if (that.loginForm.invalid) {
      that.submitted = true;

      return;
    }

    that.SpinnerService.show();
    if (!window.navigator.onLine) {
      this.SpinnerService.hide();
      this.responseError = "Server is not responding";
    } else {
      window["grecaptcha"].ready(function () {
        window["grecaptcha"]
          .execute("6LcXITEaAAAAAJ-PIRcGLjGH_EaZbplqPL7Xm8kX", {
            action: "submit",
          })
          .then(function (token) {
            that.submitted = true;

            // reset alerts on submit
            that.alertService.clear();

            that.loading = true;
            let loginData = that.loginForm.value;
            loginData["captchaToken"] = token;
            that.authenticationService
              .login(loginData)
              .pipe(first())
              .subscribe(
                (data) => {
                  that.SpinnerService.hide();

                  localStorage.setItem("adminName", data.data.username);

                  that.router.navigate([that.returnUrl]);
                  if (data["status"] == 200 && data.data.user_type == "admin") {
                    that.showSuccessToster();
                    that.router.navigate(["dashboard"]);

                  } else {
                    that.responseError = data["message"];
                  }
                },
                (error) => {
                  that.responseError = "Server is not responding";
                  that.alertService.error(error);
                  that.loading = false;
                }
              );
          });
      });
    }
  }

  showSuccessToster() {
    this.toastr.success("Login successful!", "Success!");
  }
}
