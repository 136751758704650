import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpParams, HttpHeaders } from "@angular/common/http";
import { throwError, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { catchError } from "rxjs/operators";
import { FaqAdd } from "src/app/core/models/faqAdd";
import { AuthService } from './auth.service';
import { Pages } from 'src/app/core/models/static-page';
import { appApiResources } from "./api.constant";

@Injectable({
  providedIn: "root",
})
export class FaqService {
  constructor(private http: HttpClient,private _authSer:AuthService) {}


  getAllFaq(): Observable<any> {
    return this.http
      .get(appApiResources.adminFaqManagement)
      .pipe(catchError(this.handleError));
  }

  addFaq(addFaq: FaqAdd):Observable<any>{
    return this.http
      .post(appApiResources.adminFaqManagement, addFaq)
      .pipe(catchError(this.handleError));
  }

  updateFaq(addFaq: FaqAdd,id):Observable<any>{
    const options = {
          headers: new HttpHeaders({
            Authorization: "Token " + this._authSer.getAuthorizationToken()
          }),
          params: new HttpParams()
            .set('id', id),
          reportProgress: false
        };
    return this.http
    .put(appApiResources.adminFaqManagement, addFaq,options)
    .pipe(catchError(this.handleError));
  }

  deleteFaq(id){
    const options = {
      headers: new HttpHeaders({
        Authorization: "Token " + this._authSer.getAuthorizationToken()
      }),
      params: new HttpParams()
        .set('id', id),
      reportProgress: false
    };
return this.http
.delete(appApiResources.adminFaqManagement,options)
.pipe(catchError(this.handleError));
  }

  createPage(page: Pages): Observable<Pages> {
    // const data = JSON.stringify(page);
    var reqHeader = new HttpHeaders({"Content-Type": "application/json","Authorization": localStorage.getItem('token')});
    return this.http.post<Pages>(appApiResources.adminStaticPageManagement,page)
  }

  updatecreatePage(page: Pages,id): Observable<Pages> {
    // const data = JSON.stringify(page);
    const options = {
      headers: new HttpHeaders({
        Authorization: "Token " + this._authSer.getAuthorizationToken()
      }),
      params: new HttpParams()
        .set('id', id),
      reportProgress: false
    };
    // var reqHeader = new HttpHeaders({"Content-Type": "application/json","Authorization": localStorage.getItem('token')});
    return this.http.put<Pages>(appApiResources.adminStaticPageManagement,page,options)
  }
  getStaticPage(): Observable<Pages> {
    var reqHeader = new HttpHeaders({"Authorization":localStorage.getItem('token')});
    return this.http.get<Pages>(appApiResources.adminStaticPageManagement)
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }
}
