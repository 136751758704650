import {
  Component,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
} from "@angular/core";
import {
  Sort,
  MatTableDataSource,
  MatPaginator,
  MatSort,
} from "@angular/material";

import { DemoTestedVideoService } from "src/app/shared/services/demo-tested-video.service";
import { PaypalPaymentService } from "src/app/shared/services/paypal-payment.service";
import Swal from "sweetalert2";

export interface PeriodicElement {
  id: number;
  tester_email: any;
  language: string;
  test_name: string;
  tested_by: string;
  // date_of_tested: number;
  // view: any;
}
var ELEMENT_DATA: PeriodicElement[] = [];
@Component({
  selector: "app-table-filter",
  templateUrl: "./table-filter.component.html",
  styleUrls: ["./table-filter.component.css"],
})
export class TableFilterComponent implements OnInit {
  @Input() testerList = [];

  @Output() viewSingleInfo: EventEmitter<any> = new EventEmitter<any>();

  @Output() reRenderTableList: EventEmitter<any> = new EventEmitter<any>();

  @Output() getDeclinedEmail: EventEmitter<any> = new EventEmitter<any>();

  showDetails: boolean = false;
  userList: any;
  error: any;
  allTester: any[] = [];
  passwordIsValid = false;
  userUploadTest: [];
  userTestList: [];
  userID: any;
  showUserDetails: boolean = false;
  date: any;
  language: any;
  userId: any;
  image: any;
  fieldTextType: boolean;
  searchText: string;
  emailTester: never;
  allSortedTester = [];
  pageLength: number = 0;
  pageIndex: number = 0;
  start_url;
  displayedColumns: string[];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  totalTestedtestCount: any;
  averageRating: any;
  statusShow: any;

  data = [];

  testedVideos: [];
  demoTestedList: never;
  isApproved: boolean;
  approveList: any;
  @ViewChild(MatPaginator, { static: false }) set content(
    paginator: MatPaginator
  ) {
    this.dataSource.paginator = paginator;
  }

  @ViewChild(MatSort, { static: false }) set conten1t(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  constructor(
    private _testedDemoSer: DemoTestedVideoService,
    private _paymentServ: PaypalPaymentService
  ) {}

  ngOnInit() {
    this.allTesterList();
    this.displayedColumns = this.testerList[0];
    this.allTester = this.testerList;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  allTesterList() {
    this.dataSource = new MatTableDataSource<PeriodicElement>(
      this.testerList[1]
    );
  }

  formatTimestamp(date){
    const date1 = new Date(date);
    let date2 = date1.toLocaleString('en-GB', {year: 'numeric', day: 'numeric', month: 'numeric',  hour: '2-digit', minute: '2-digit', hour12: true}); 
    return date2;
  }

  deleteDeclinedTester(email) {
    this.getDeclinedEmail.emit(email);
  }

  rejectPaymentforTest(data) {
    let video_id = data.video_id;
    Swal.fire({
      title: "Are you sure?",
      text: "You want to reject this payout!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Reject!",
    }).then((result) => {
      if (result.value) {
        this._paymentServ.cancelVideoPayout(video_id).subscribe((res) => {
          if (res["status"]==200) {
            this.reRenderTableList.next(true);
          }
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          (error) => {
            
          };
        });
      }
    });
  }

  viewDataID(singleInfo) {
    this.viewSingleInfo.emit(singleInfo);
  }

  getBadgeLabel(statusCode) {
    let labelText = "";
    let className = "";
    let statusBtn = "";
    let editTest = "";
    let btnHide = "";
    switch (statusCode) {
      case 0:
        labelText = "Running";
        className = "badge badge-info";
        statusBtn = "Get videos";
        editTest = "";
        btnHide = "none";
        break;
      case 1:
        labelText = "pending";
        className = "badge badge-warning";
        statusBtn = "Get videos";
        editTest = "Edit Test";
        btnHide = "block";
        break;
      case 2:
        labelText = "Draft";
        className = "badge badge-warning";
        statusBtn = "Get videos";
        editTest = "Edit Test";
        btnHide = "none";
        break;
      case 6:
        labelText = "CANCELLED";
        className = "badge badge-danger";
        statusBtn = "Reactive Test";
        editTest = "Edit Test";
        btnHide = "block";
        break;
      case 3:
        labelText = "FINISHED";
        className = "badge badge-success";
        statusBtn = "Get new video";
        editTest = "";
        btnHide = "none";
        break;
      case 7:
        labelText = "CONFIRMATION REQUIRED";
        className = "draft red";
        statusBtn = "Reniew video";
        editTest = "Edit Test";
        btnHide = "block";
        break;
      case 5:
        labelText = "Archive";
        className = "badge badge-warning";
        statusBtn = "Get videos";
        editTest = "Edit Test";
        btnHide = "block";
        break;
      default:
        break;
    }
    return {
      labelText,
      className,
      statusBtn,
      editTest,
      btnHide,
    };
  }

  sortData(sort: Sort) {
    const data = this.testerList[1].slice();
    if (!sort.active || sort.direction === "") {
      this.allSortedTester = data;
      return;
    }

    this.allSortedTester = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "id":
          return compare(a["id"], b["id"], isAsc);
        case "Username":
          return compare(a["username"], b["username"], isAsc);
        case "Created":
          return compare(a["date_joined"], b["date_joined"], isAsc);
        case "Email":
          return compare(a["email"], b["email"], isAsc);
        // case 'carbs': return compare(a.carbs, b.carbs, isAsc);
        // case 'protein': return compare(a.protein, b.protein, isAsc);
        default:
          return 0;
      }
    });
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
