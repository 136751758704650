import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpErrorResponse,
} from "@angular/common/http";
import { retry, catchError, map } from "rxjs/operators";
import { Observable, throwError, BehaviorSubject, Subject } from "rxjs";
import { environment } from "src/environments/environment";

import { TokenParms } from "../../core/models/token-parms";
import { User } from "../../core/models/user";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  serverUrl = environment.apiUrl;
  errorData: {};
  url = environment.apiUrl + "admin/password_reset/";
  private currentUserSubject: BehaviorSubject<TokenParms>;
  public currentUser: Observable<User>;
  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<TokenParms>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }
  public get currentUserValue(): TokenParms {
    return this.currentUserSubject.value;
  }

  login(login: TokenParms): Observable<any> {
    var reqHeader = new HttpHeaders({ "Content-Type": "application/json" });

    return this.http
      .post<any>(`${environment.apiUrl}admin/login/`, login, {
        headers: reqHeader,
      })

      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem("currentUser", JSON.stringify(user));
          localStorage.setItem("token", user.data["token"]);

          this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  isLoggedIn() {
    if (localStorage.getItem("currentUser")) {
      return true;
    }
    return false;
  }

  getAuthorizationToken() {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    console.log('&&&&&&&&',currentUser.data.token);
    return currentUser.data.token;
  }

  loggedIn() {
    return !!localStorage.getItem("token");
  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem("currentUser");
    this.currentUserSubject.next(null);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    this.errorData = {
      errorTitle: "Oops! Request for document failed",
      errorDesc: "Something bad happened. Please try again later.",
    };
    return throwError(this.errorData);
  }

  logoutAdmin(): Observable<any> {
    return this.http
      .get(environment.apiUrl + "user/logout/")
      .pipe(catchError(this.handleError));
  }
}
