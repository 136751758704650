import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { finalize, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import Swal from "sweetalert2";
import { LoaderService } from './services/loader.service';


@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {
  requestCount: number = 0;

  constructor(private router: Router ,private loaderServ:LoaderService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    this.requestCount = this.requestCount + 1;
    this.loaderServ.isLoding.next(true);

    if (req.headers.get("Content-Type") == "application/json") {
      return next.handle(req.clone());
    }
    if (localStorage.getItem("token") != null) {
      const request = req.clone({
        headers: req.headers.set(
          "Authorization",
          "Token " + localStorage.getItem("token")
        ),
      });
      return next.handle(request).pipe(
        tap(
          (event: HttpEvent<any>) => {
            if (event instanceof HttpResponse && event.status === 200) {
              this.loaderServ.isLoding.next(false);
              if (event.body.status !== 200) {
                this.loaderServ.isLoding.next(false);
              }
            }
          },
          (err: any) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status !== 401) {
                return;
              }
              // alert("Your session has expired!");
              this.loaderServ.isLoding.next(false);
              Swal.fire({
                title: 'Your session has expired!',
                showClass: {
                  popup: 'animated fadeInDown faster'
                },
                hideClass: {
                  popup: 'animated fadeOutUp faster'
                }
              })
              localStorage.clear();
              this.router.navigate(["login"]);
            }
          }
        ),
        finalize(()=>{
          this.requestCount--;
          this.loaderServ.isLoding.next(false);
        })
      );
    }
    // else{
    //     this.router.navigateByUrl('/login');
    // }
  }
}
