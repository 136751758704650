import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  FormControl,
} from "@angular/forms";
// import { CreateNewTestService } from "src/app/shared/services/create-new-test.service";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { TestsService } from 'src/app/shared/services/tests.service';
@Component({
  selector: 'app-demo-test',
  templateUrl: './demo-test.component.html',
  styleUrls: ['./demo-test.component.css']
})
export class DemoTestComponent implements OnInit {
  TestInstructions: Boolean = true;
  createNewTests: FormGroup;
  st
  audienceData: FormGroup;
  templateShow = "Please remember to think out loud during your test and tell us if there’s any information missing. You can input fake information if you need to sign up or anything.  ###TASK###  Take a look at this site and tell us what you think it is. (You can scroll if you want but please don’t click on anything yet.)  -- What’s the first thing you notice? -- What can you do on this site? -- What products or services are offered on this site? -- Who is this site intended for?  ###TASK###  Now imagine the products or services offered on this site are intended for you and go ahead to do whatever you think you’re supposed to do on this site (e.g. buy a product, make a booking, create an account, etc.).  Thank you for your help! "
  myVar1 = false;
  newTestData = {};
  audienceData1 = {};
  showField: boolean = true;
  
  isActive = false;
  // templates = ["Default", "E-Commerce", "Booking", "Saas", "Corporate"];
  language = ["English", "German"];
  audience = [
    "Fastest results",
    "Choose specific demographics",
    "Get certified UX and User Research Experts to take the test",
  ];
  showRadio: boolean = false;
  testerAge = ["All", "18-24", "25-34", "35-50", "51 and Other"];
  testerGender = ["All", "Female", "Male"];
  testerRegion = [
    "All",
    // "india",
    "North America",
    "South America",
    "Asia",
    "Australia",
    "Africa",
    "Europe",
    "Antarctic"
  ];
  person = "All"
  submitted: boolean = false;
  temValue: any;
  mystyle1: string = "none";
  allProjectList = [];
  templates: string[];
  templateData: any;
  selectedInstructionType: any;
  allDummyData: any;

  constructor(
    private fb: FormBuilder,
    private _testServ: TestsService,
    private _router: Router
  ) { }

  ngOnInit() {
    this.createNewTests = this.fb.group({
      test_name: ["", Validators.required],
      project_name: ["", Validators.required],
      project_flag: [false],
      start_url: [
        "",
        [
          Validators.required,
          Validators.pattern(
            "(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})"
          ),
        ],
      ],
      language: ["", Validators.required],
      // url_type:[""],
      is_app: [false],
      // articipants_audience: [""],
      // test_instructions: ["", Validators.required],
      test_instructions: new FormArray([]),

      template_name: ["Default"],
      articipants_audience: this.fb.group({
        articipants_type: ["Choose specific demographics"],
        gender: ["All"],
        region: ["All"],
        age: ["All"],
      }),
    });

    this.audienceData = this.fb.group({
      participants_audience: [""],
    });

    this.templateManagement()
    this.getAllDummyTest()
    this._testServ.gelAllProjectList().subscribe((res) => {
      let newFormulalist = res.data.filter(
        (v, i) =>
          res.data.findIndex((item) => item.project_name == v.project_name) ===
          i
      );
      newFormulalist.forEach((res) => {
        this.allProjectList.push(res.project_name);
      })
    })

  }

  templateManagement() {
    this._testServ.templateManagement()
      .subscribe(res => {
        let tempName = []
        for(let i in res['data']){
          tempName.push(i)
        }
        this.selectedInstructionType = tempName[1]
        // this.selectedInstructionType = 'Default'
        this.templateData = res['data']
        this.templates = Object.keys(this.templateData)

        this.templateData[this.selectedInstructionType].forEach(text => {
          const control = new FormControl(text, Validators.required);
          (<FormArray>this.createNewTests.get("test_instructions")).push(control); 
        });

      })
  }

  onAddTests() {
    if (this.createNewTests.get("test_instructions")['length'] < 5) {
      const control = new FormControl(null, Validators.required);
      (<FormArray>this.createNewTests.get("test_instructions")).push(control);
    }
    else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: 'You can not add more than 5 Task.'
      })
    }
  }

  removeTest(indexoftest): void {
    (<FormArray>this.createNewTests.get("test_instructions")).removeAt(indexoftest);
  }

  addStyle() {
    this.mystyle1 = "block";
  }
  hideStyle() {
    this.mystyle1 = "none";
  }

  changeTestInstructionType(testInstructionType) {
    this.selectedInstructionType = testInstructionType

    // Remove all element from test_instructions array
    while (this.createNewTests.get("test_instructions")['length'] !== 0) {
      (<FormArray>this.createNewTests.get("test_instructions")).removeAt(0);
    }

    // Add value of selected test_instructions type in test_instructions array
    this.templateData[testInstructionType].forEach(text => {
      let control = new FormControl(text, Validators.required);
      (<FormArray>this.createNewTests.get("test_instructions")).push(control);
    })
  }

  BindData() {
    this.showField = true;
  }

  createTests() {
    this.submitted = true;
    if (this.TestInstructions) {
      if (
        this.TestInstructions
        && this.createNewTests.value['test_name'].trim()
        && this.createNewTests.value['project_name'].trim()
        && this.createNewTests.value['start_url'].trim()
        && this.createNewTests.value['language'].trim()
      ) {
        this.TestInstructions = !this.TestInstructions;
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: 'Please fill in the required fields'
        });
        return;
      }
    } else {
      this.TestInstructions = !this.TestInstructions;
    }
  }

  onAudience() {
    this.audienceData1 = this.audienceData.value;
  }

  toggle() {
    this.showRadio = !this.showRadio;
  }

  onCreateNewTest() {
    
    this.submitted = true;
    if (this.createNewTests.invalid) {
      return;
    }
    this.submitted = true;
    this._testServ
      .dummyTestCreate(this.createNewTests.value)
      .subscribe((testRes) => {
        if (testRes["status"] == 200) {
          this.getAllDummyTest()
          Swal.fire(
            "Test created!",
            this.createNewTests.value.test_name + " test name is created!",
            "success"
          );
          setTimeout(() => {
            this._router.navigate(["demo-test"]);
            this.TestInstructions = true
            
           
          }, 1000);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: testRes.message,
            // footer: '<a href>Why do I have this issue?</a>'
          });

          // alert(testRes.message)
        }
      });
  }

  getAllDummyTest() {
    this._testServ.getCreateTest().subscribe(res => {
      this.allDummyData = res.data
    })
  }

  deleteDummyTest(testID){

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this._testServ.deleteTest(testID).subscribe(res=>{
          this.getAllDummyTest()
          Swal.fire(
            'Deleted!',
            'Your test has been deleted.',
            'success'
          )
        })
        
      }
    })
  
  }
}
