import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
// import { DashboardTestComponent } from '../dashboard-test/dashboard-test.component';

@NgModule({
  declarations: [
    // DashboardComponent,
    // DashboardTestComponent,
    
  ],
  imports: [
    CommonModule
  ]
})
export class DashboardModule { }
