import { Component, OnInit, ViewChild } from "@angular/core";
import { environment } from "src/environments/environment";
import { FormGroup, FormBuilder } from "@angular/forms";

import { TestersService } from "src/app/shared/services/testers.service";

import { TestsService } from "src/app/shared/services/tests.service";

@Component({
  selector: "app-tested-video",
  templateUrl: "./tested-video.component.html",
  styleUrls: ["./tested-video.component.css"],
})
export class TestedVideoComponent implements OnInit {
  EnvApiUrl : string = environment.apiUrl;
  pushDataInList: any[] = [];
  userList: any;
  error: any;
  allTester: [] = [];
  regForm: FormGroup;
  massage: string;
  registerResMsg: string;
  errMsg = [];
  Error = false;
  showTest: boolean;

  submitted: boolean = false;
  passwordIsValid = false;
  // @ViewChild(FormGroupDirective ,{static:false}) formGroupDirective: FormGroupDirective;
  registerResEmailMsg: any;
  hideSuccessMessage: boolean = false;
  urlType: string;
  clientSigup = false;
  username: any;
  userUploadTest: [];
  userTestList: [];
  userID: any;
  showUserDetails: boolean = false;
  date: never;
  language: never;
  total_income: never;
  paypal_address: never;
  billing_address: never;
  country: never;
  devices: never;
  dob: never;
  gender: never;
  userId: never;
  image: never;
  fieldTextType: boolean;
  searchText: string;
  emailTester: never;
  allSortedTester = [];
  pageLength: number = 0;
  pageIndex: number = 0;

  displayedColumns: string[] = [
    "id",
    "test_id",
    "user_id",
    "test_name",
    "tested_by",
    "date_of_tested",
    "status",
    "view",
  ];

  totalTestedtestCount: any;
  averageRating: any;
  statusShow: any;

  data = [];

  testedVideos: [];
  allTestedList: any[] = [];
  testID: any;

  constructor(
    private _testerService: TestersService,
    private _tests: TestsService
  ) {}

  ngOnInit() {
    this.pushDataInList = [];
    this.getAllTestedList();
  }

  getBadgeLabel(statusCode) {
    let labelText = "";
    let className = "";
    let statusBtn = "";
    let editTest = "";
    let btnHide = "";
    switch (statusCode) {
      case 0:
        labelText = "Running";
        className = "badge badge-info";
        statusBtn = "Get videos";
        editTest = "";
        btnHide = "none";
        break;
      case 1:
        labelText = "pending";
        className = "badge badge-warning";
        statusBtn = "Get videos";
        editTest = "Edit Test";
        btnHide = "block";
        break;
      case 2:
        labelText = "Draft";
        className = "badge badge-warning";
        statusBtn = "Get videos";
        editTest = "Edit Test";
        btnHide = "none";
        break;
      case 6:
        labelText = "CANCELLED";
        className = "badge badge-danger";
        statusBtn = "Reactive Test";
        editTest = "Edit Test";
        btnHide = "block";
        break;
      case 3:
        labelText = "FINISHED";
        className = "badge badge-success";
        statusBtn = "Get new video";
        editTest = "";
        btnHide = "none";
        break;
      case 7:
        labelText = "CONFIRMATION REQUIRED";
        className = "draft red";
        statusBtn = "Reniew video";
        editTest = "Edit Test";
        btnHide = "block";
        break;
      case 5:
        labelText = "Archive";
        className = "badge badge-warning";
        statusBtn = "Get videos";
        editTest = "Edit Test";
        btnHide = "block";
        break;
      default:
        break;
    }
    return {
      labelText,
      className,
      statusBtn,
      editTest,
      btnHide,
    };
  }

  getAllTestedList() {
    this._tests.getAllTestsList().subscribe((res) => {
      this.allTestedList = res.data.tested_test;
      this.pushDataInList.push(this.displayedColumns);
      this.pushDataInList.push(res.data.tested_test);
    });
    this.showTest = true;
  }

  passwordValid(event) {
    this.passwordIsValid = event;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  allTesterList() {
    this.userList = this._testerService.getAllTesterList().subscribe(
      (res) => {
        this.allTester = res.data.tester_details;
        this.allSortedTester = this.allTester;
        this.pageIndex = 0;
        this.pageLength = this.allTester.length;

        // this.userUploadTest = res.data.user_uploaded_test
      },
      (error) => (this.error = error)
    );
  }

  backProfile() {
    this.showUserDetails = true;
    this.allTesterList();
  }

  backBtn() {
    this.showTest = true;
  }

  getSingleInfo(userID) {
    this.showTest = false;
    this.testID = userID.id;
    // this.allTesterList();
    this.userTestList = [];
  }
}
