import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { appApiResources } from "./api.constant";

@Injectable({
  providedIn: "root",
})
export class PaypalPaymentService {
  constructor(private http: HttpClient) {}
 
  getPaymentList(flagStatus) {
    const options = {
      params: new HttpParams().set("flag", flagStatus),
      reportProgress: false,
    };
    return this.http
      .get(appApiResources.paypalPaymentAllList, options)
      .pipe(catchError(this.handleError));
  }

  cancelVideoPayout(video_id) {
    const options = {
      params: new HttpParams().set("video_id",video_id ),
      reportProgress: false,
    };
    return this.http
      .get(appApiResources.rejectVideoPayout, options)
      .pipe(catchError(this.handleError));
  }

  startPendingPayout() {
    return this.http
      .get(appApiResources.payoutStart)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }
}
