import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PaypalPaymentService } from 'src/app/shared/services/paypal-payment.service';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.css']
})
export class PaymentSuccessComponent implements OnInit {
  paymentRecievedList: any;
  pushDataInList: any[] = [];
  displayedColumns: any[] = [
    "id",
    "email",
    "schedule_date",
    "receiver_paypal_id",
    "payout_status",
    "view",
  ];
  isDetailsPayment: boolean = false;
  paymentDetails: any[];
  constructor(private router:Router,private _paymentService:PaypalPaymentService) { }

  ngOnInit() {
    this.pushDataInList = [];
    this.paymentDetails = [];
    this.getSuccessPaymentList();
  }

  
  backTo(){
    this.router.navigate(['payment'])
  }

  getSuccessPaymentList(){
    let flag = 'received';
    this._paymentService.getPaymentList(flag).subscribe(res=>{
      this.paymentRecievedList = res['data'];
      this.pushDataInList.push(this.displayedColumns);
      this.pushDataInList.push(res["data"]);
    })
  }

  reRenderList(event) {
    if (event) {
      this.getSuccessPaymentList();
    }
  }

  getSingleInfo(event) {
    this.router.navigate(["payment/details"], { state: { details: event ,header:'Tester Successfull Payment Details' } });
  }

}
