import { Component, OnInit, ViewChild } from "@angular/core";
import { environment } from "src/environments/environment";
import { FormBuilder } from "@angular/forms";

import { TestersService } from "src/app/shared/services/testers.service";
import Swal from "sweetalert2";

import {
  Sort,
  MatTableDataSource,
  MatPaginator,
  MatSort,
} from "@angular/material";
import { TestsService } from "src/app/shared/services/tests.service";
import { DemoTestedVideoService } from "src/app/shared/services/demo-tested-video.service";

export interface PeriodicElement {
  id: number;
  tester_email: any;
  language: string;
  test_name: string;
  tested_by: string;
  date_of_tested: number;
  view: any;
}

var ELEMENT_DATA: PeriodicElement[] = [];
@Component({
  selector: "app-demo-tested-video",
  templateUrl: "./demo-tested-video.component.html",
  styleUrls: ["./demo-tested-video.component.css"],
})
export class DemoTestedVideoComponent implements OnInit {
  EnvApiUrl : string = environment.apiUrl;
  showDetails: boolean = false;
  pushDataInList: any[] = [];
  userList: any;
  error: any;
  allTester: [] = [];
  passwordIsValid = false;
  // @ViewChild(FormGroupDirective ,{static:false}) formGroupDirective: FormGroupDirective;

  userUploadTest: [];
  userTestList: [];
  userID: any;
  showUserDetails: boolean = false;
  date: any;
  language: any;
  userId: any;
  image: any;
  fieldTextType: boolean;
  searchText: string;
  emailTester: never;
  allSortedTester = [];
  pageLength: number = 0;
  pageIndex: number = 0;
  start_url;
  displayedColumns: any[] = [
    "id",
    "tester_email",
    "language",
    "test_name",
    "date_of_tested",
    "view",
  ];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  totalTestedtestCount: any;
  averageRating: any;
  statusShow: any;
  data = [];

  testedVideos: [];
  demoTestedList: never;
  isApproved: boolean;
  approveList: any;
  videoRatingValue: number = 0;
  prefillRejectionMsg: any = "";

  // reject test prefilled data array
  prefillRejectionMsgArray = [
    {
      "keyword": "No sound",
      "message": "Your uploaded recording did not contain any sound. Check your microphone setup."
    },
    {
      "keyword": "Background noise",
      "message": "The background noise in your video is too loud. Go to a quiet and undisturbed place for testing."
    },
    {
      "keyword": "Sound quality",
      "message": "Low Sound Quality: The quality of your microphone is not sufficient. Get a higher quality microphone to perform tests."

    },
    {
      "keyword": "Sound low",
      "message": "Your recorded sound is too low. You need to speak louder or get closer to the microphone."

    },
    {
      "keyword": "Distracted",
      "message": "You seem distracted while working on the tasks. Please go to a distraction-free place and focus on the test."

    },
    {
      "keyword": "Pronunciation",
      "message": "You spoke too unclearly. Make sure your pronunciation is clear and distinct."

    },
    {
      "keyword": "Inappropiate",
      "message": "You use inappropriate language/swear words. Please pay attention to how you express yourself."

    },
    {
      "keyword": "Expert feedback",
      "message": "You give your feedback from an expert's perspective. Please only use language that is common for consumers."

    },
    {
      "keyword": "Time requirement",
      "message": "You didn't meet the time requirement. Please pay attention to the timer in the UserPeek tab."

    },
    {
      "keyword": "English level",
      "message": "Your English level does not seem to correspond to the required C1 level."

    },
    {
      "keyword": "Feedback detail",
      "message": "You did not communicate your feedback in sufficient detail. Follow the think-out-loud method."

    },
    {
      "keyword": "Follow tasks",
      "message": "You did not follow exactly what was specified in the task description. Read the task descriptions carefully and follow them exactly."

    },
    {
      "keyword": "Device issue",
      "message": "It seems that your device is not suitable for performing tests. Please try another device."

    },
    {
      "keyword": "Addons",
      "message": "You have installed addons in your browser that interfere with recording/testing. It is best to uninstall/deactivate all addons before testing."

    },
    {
      "keyword": "Profile info",
      "message": "Your profile contains information that does not seem to be accurate. Please note what is written about this in the Terms of Service."

    },
    {
      "keyword": "No video",
      "message": "Your uploaded recording does not contain a captured video image. Check your technical setup."

    },
    {
      "keyword": "File corrupted",
      "message": "The uploaded file seems to be corrupted. Please repeat the test."

    },
    {
      "keyword": "Wrong screen",
      "message": "It seems that you have recorded the wrong screen. Please check your sharing settings."

    }
  ]


  @ViewChild(MatPaginator, { static: false }) set content(
    paginator: MatPaginator
  ) {
    this.dataSource.paginator = paginator;
  }

  @ViewChild(MatSort, { static: false }) set conten1t(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  constructor(
    private _tests: TestsService,
    private _testedDemoSer: DemoTestedVideoService
  ) {}

  ngOnInit() {
    this.pushDataInList = [];
    this.allTesterList();
  }

  passwordValid(event) {
    this.passwordIsValid = event;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  // Register the Tester And Client

  allTesterList() {
    this._testedDemoSer.getDemoTestedVideoDetails().subscribe(
      (res) => {
        this.allTester = res.data;
        this.pushDataInList.push(this.displayedColumns);
        this.pushDataInList.push(res.data);
      },
      (error) => (this.error = error)
    );
  }

  approveRejctTest(data) {
    if(this.videoRatingValue == 0){
      Swal.fire({text: "Please rate the video, before Approval."});
      console.log('rating not selected');
    } else{
      let parms = {
        email: data.tester_email,
        test_id: data.test_id,
        tester_id: data.tester,
        is_approved: true,
        tested_test_id: data.id,
        rating: this.videoRatingValue
      };
      Swal.fire({
        title: "Are you sure?",
        text: "You want to approve this tester for regular test !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, approve it!",
      }).then((result) => {
        if (result.value) {
          this._testedDemoSer.approveRejctTest(parms).subscribe(
            (res) => {
              this.allTesterList();
              this._testedDemoSer.checkApproveAndReject().subscribe((res) => {
                this.approveList = res.data;
                this.approveList.filter((res) => {
                  if (res.test_id == data.test_id) {
                    this.isApproved = res.is_approved;
                  }
                });
              });
  
              Swal.fire(
                "Approved!",
                "This tester has been approved  for do the regular test.",
                "success"
              );
            },
            (error) => {
              Swal.fire("Error!", "Something went wrong .", "error");
            }
          );
        }
      });
    }


  }

  RejectTest(data) {
    let parms = {
      email: data.tester_email,
      test_id: data.test_id,
      tester_id: data.tester,
      is_approved: false,
      tested_test_id: data.id,
      rating: this.videoRatingValue
    };

    let rejectMsgArrayElement = document.getElementById("reject-text-box").childNodes;
    let rejectMsgList = [];

    if(rejectMsgArrayElement.length > 0){
      Array.prototype.forEach.call(rejectMsgArrayElement, function(item) {
        console.log("item", item.innerHTML)
        rejectMsgList.push(item.innerHTML);
      });
      parms['feedback'] = rejectMsgList;

      Swal.fire({
        title: "Are you sure?",
        text: "Do you want to reject this test",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Reject it!",
      }).then((result) => {
        if (result.value) {
          this._testedDemoSer.approveRejctTest(parms).subscribe(
            (res) => {
              if (res.status == 200) {
                this.allTesterList();
                this._testedDemoSer.checkApproveAndReject().subscribe((res) => {
                  this.approveList = res.data;
                  this.approveList.filter((res) => {
                    if (res.test_id == data.test_id) {
                      this.isApproved = res.is_approved;
                    }
                  });
                });
                Swal.fire(
                  "Rejected!",
                  "This tester has been rejected  for do the regular test.",
                  "success"
                );
              } else {
                Swal.fire("Failed!", res.message, "error");
              }
            },
            (error) => {
              Swal.fire("Error!", "Something went wrong .", "error");
            }
          );
        }
      });
    } 
    else {
      Swal.fire({text: "Please add some feedback to reject test"});
    }






    // Swal.fire({
    //   title: "Are you sure?",
    //   text: "Please enter a feedback for your rejection",
    //   inputPlaceholder: 'Enter your feedback here..',
    //   icon: "warning",
    //   showCancelButton: true,
    //   confirmButtonColor: "#3085d6",
    //   cancelButtonColor: "#d33",
    //   confirmButtonText: "Yes, reject it!",
    //   input: 'textarea',
    //   inputValue: this.prefillRejectionMsg,
    //   inputAttributes: {
    //     autocapitalize: 'off'
    //   },
    //   preConfirm: (login) => {
        
    //     if(!login){
    //       Swal.showValidationMessage(
    //         "please enter a valid feedback"
    //         )
    //       }
    //       return login
    //   },
    //   allowOutsideClick: () => !Swal.isLoading()
    // }).then((result) => {
    //   if (result.value) {
    //     parms['feedback'] = result.value
    //     console.log('parms',parms)
    //     this._testedDemoSer.approveRejctTest(parms).subscribe(
    //       (res) => {
    //         if (res.status == 200) {
    //           this.allTesterList();
    //           this._testedDemoSer.checkApproveAndReject().subscribe((res) => {
    //             this.approveList = res.data;
    //             this.approveList.filter((res) => {
    //               if (res.test_id == data.test_id) {
    //                 this.isApproved = res.is_approved;
    //               }
    //             });
    //           });
    //           Swal.fire(
    //             "Rejected!",
    //             "This tester has been rejected  for do the regular test.",
    //             "success"
    //           );
    //         } else {
    //           Swal.fire("Failed!", res.message, "error");
    //         }
    //       },
    //       (error) => {
    //         Swal.fire("Error!", "Something went wrong .", "error");
    //       }
    //     );
    //   }
    // });

    


  }

  backBtn() {
    this.showDetails = false;
    this.allTesterList();
  }

  getSingleInfo(data) {
    this.allTesterList();
    let alltestedList = this.allTester;
    alltestedList.find((res) => {
      if (res["id"] == data.id) {
        this.demoTestedList = res;
      }
    });
    this.handleApprovedBtn(data);
    this.showDetails = true;
  }

  handleApprovedBtn(data) {
    this._testedDemoSer.checkApproveAndReject().subscribe((res) => {
      this.approveList = res.data;
      if (this.approveList.some((isApp) => isApp.tested_test_id === data.id)) {
        this.approveList.filter((res) => {
          if (res.tested_test_id === data.id) {
            this.isApproved = res.is_approved;
          }
        });
      } else {
        this.isApproved = null;
      }
    });
  }

  handleStarRatingClick(event){

    // Reset color
    let allStarIcon = document.getElementsByClassName("video-rating-icon");
    for (let i = 0; i < allStarIcon.length; i++) {
        allStarIcon.item(i).setAttribute("style", "background-image: url('../../../assets/images/icons/star-gray.png')");
    }
    
    let ratingStarIcon = document.getElementsByClassName("video-rating-icon");
    
    // If click on star 1
    if(event.target == ratingStarIcon[0]){
      
      for(let i = 0; i <= 0; i++){
        let starIcon1 = document.getElementsByClassName("video-rating-icon");
        starIcon1.item(i).setAttribute("style", "background-image: url('../../../assets/images/icons/star-black.png')");
      }

      this.videoRatingValue = 1;
      console.log(this.videoRatingValue);

    }

    // If click on star 2
    if(event.target == ratingStarIcon[1]){

      for(let i = 0; i <= 1; i++){
        let starIcon2 = document.getElementsByClassName("video-rating-icon");
        starIcon2.item(i).setAttribute("style", "background-image: url('../../../assets/images/icons/star-black.png')");
      }

      this.videoRatingValue = 2;
      console.log(this.videoRatingValue);

    }

    // If click on star 3
    if(event.target == ratingStarIcon[2]){

      for(let i = 0; i <= 2; i++){
        let starIcon3 = document.getElementsByClassName("video-rating-icon");
        starIcon3.item(i).setAttribute("style", "background-image: url('../../../assets/images/icons/star-black.png')");
      }

      this.videoRatingValue = 3;
      console.log(this.videoRatingValue);

    }

    // If click on star 4
    if(event.target == ratingStarIcon[3]){

      for(let i = 0; i <= 3; i++){
        let starIcon4 = document.getElementsByClassName("video-rating-icon");
        starIcon4.item(i).setAttribute("style", "background-image: url('../../../assets/images/icons/star-black.png')");
      }

      this.videoRatingValue = 4;
      console.log(this.videoRatingValue);

    }

    // If click on star 5
    if(event.target == ratingStarIcon[4]){

      for(let i = 0; i <= 4; i++){
        let starIcon5 = document.getElementsByClassName("video-rating-icon");
        starIcon5.item(i).setAttribute("style", "background-image: url('../../../assets/images/icons/star-black.png')");
      }

      this.videoRatingValue = 5;
      console.log(this.videoRatingValue);

    }



  }

  addPrefillMsg(message){
    // console.log("event", event)
    this.prefillRejectionMsg = message;
    let ulElement =  document.getElementById("reject-text-box");
    let previousContent = ulElement.innerHTML;
    ulElement.innerHTML = `${previousContent}<li>${message}</li>`;
  }

}
