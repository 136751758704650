import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DashboardComponent } from "./dashboard/dashboard.component";


// import { VideosComponent } from "../videos/videos.component";
// import { DashboardTestComponent } from "./dashboard-test/dashboard-test.component";
// import { AuthGuard } from "../auth.guard";
// import { DashboardTestEditComponent } from "./dashboard-test/dashboard-test-edit/dashboard-test-edit.component";
// import { VideosComponent } from '../modules/videos/videos.component';
// import { DashboardTesterComponent } from './dashboard-tester/dashboard-tester.component';

const routes: Routes = [
  // {
  //   path: "dashboard",
  //   component: DashboardComponent,
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: "dashboardTester",
  //   component: DashboardTesterComponent,
  //   canActivate: [AuthGuard]
  // },

  // {
  //   path: "videos",
  //   component: VideosComponent,
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: "dashboard_tests",
  //   component: DashboardTestComponent,
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: "dashboard_tests/edit/:id",
  //   component: DashboardTestEditComponent,
  //   canActivate: [AuthGuard]
  // }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoreRoutingModule {}
