import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { PaypalPaymentService } from "src/app/shared/services/paypal-payment.service";

@Component({
  selector: "app-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.css"],
})
export class PaymentComponent implements OnInit {
  awaitingListLength: any;
  successfullListLength: any;
  unSuccessfullListLength: any;
  constructor(private _paymentServ: PaypalPaymentService) {}

  ngOnInit() {
    this.getAwaitingPayment();
    this.getSuccessfullPayment();
    this.getUnSuccessfullPayment();
  }

  getAwaitingPayment() {
    let flag = "awaiting";
    this._paymentServ.getPaymentList(flag).subscribe((res) => {
       this.awaitingListLength = res['data'].length
    });
  }

  getSuccessfullPayment() {
    let flag = "received";
    this._paymentServ.getPaymentList(flag).subscribe((res) => {
       this.successfullListLength = res['data'].length
    });
  }

  getUnSuccessfullPayment() {
    let flag = "unsuccessful";
    this._paymentServ.getPaymentList(flag).subscribe((res) => {
       this.unSuccessfullListLength = res['data'].length
    });
  }


}
