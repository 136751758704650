import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormArray,
  FormControl,
  Validators,
} from "@angular/forms";
import { TestsService } from "src/app/shared/services/tests.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-template-management",
  templateUrl: "./template-management.component.html",
  styleUrls: ["./template-management.component.css"],
})
export class TemplateManagementComponent implements OnInit {
  // orderForm: FormGroup;
  // items: FormArray;
  submitted: boolean = false;
  showVideoDetails;
  public form: FormGroup;
  tasks: FormArray;
  tempList: any;
  allData;
  json = {
    items: [
      {
        templatename: "Defult",
        tasks: ["task-1", "task-2 ", "task-3"],
      },
      {
        templatename: "Eccormce",
        tasks: ["task-1 ", "task-2"],
      },
      {
        templatename: "co-oprate",
        tasks: ["task-1", "task-2", "task-3"],
      },
    ],
  };
  temData: any;

  constructor(private formBuilder: FormBuilder, private _test: TestsService) {}

  ngOnInit() {
    // this.orderForm = this.formBuilder.group({
    //   items: new FormArray([this.createItem()])
    // });
    // this.getTemplateList()
    this.createTemplateArray();
  }

  createTemplateArray() {
    this._test.getTemplateData().subscribe((res) => {
      this.temData = res["data"].items;
      this.tempList = res["data"];
      const items = this.temData || [];
      this.form = new FormGroup({
        items: new FormArray(items.map((x) => this.setItemsArray(x))),
      });
    });
  }
  get itemsArray() {
    return this.form.get("items") as FormArray;
  }
  getTasks(index: number) {
    return this.itemsArray.at(index).get("tasks") as FormArray;
  }

  getTemplateData() {
    this._test.getTemplateData().subscribe((res) => {
      this.temData = res["data"].items;
    });
  }

  addItem() {
    this.itemsArray.push(this.setItemsArray());
  }
  removeItem(index: number) {
    this.itemsArray.removeAt(index);
  }

  addTask(index: number) {
    if (this.form.get("items")["controls"][index].controls.tasks.length < 5) {
      this.getTasks(index).push(new FormControl("", Validators.required));
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You can not add more than 5 Task.",
      });
    }
  }
  removeTask(index: number, indexTask: number) {
    this.getTasks(index).removeAt(indexTask);
  }
  setItemsArray(data: any = null): FormGroup {
    data = data || { templatename: null, tasks: null };
    return new FormGroup({
      templatename: new FormControl(data.templatename, Validators.required),
      tasks: data.tasks
        ? new FormArray(data.tasks.map((x) => new FormControl(x)))
        : new FormArray([new FormControl("", Validators.required)]),
    });
  }
  saveTemplate() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.submitted = true;

    this._test.addTemplate(this.form.value).subscribe((testRes) => {
      if (testRes["message"] == 200) {
        this.getTemplateData();
        Swal.fire("Test created!", " Template  is created!", "success");
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: testRes.status,
          // footer: '<a href>Why do I have this issue?</a>'
        });

        // alert(testRes.message)
      }
    });
    this.allData = this.form.value;
  }

  DeleteTemp(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this Template!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        this._test.deleteTemplate(id).subscribe((res) => {
          if (res.status == 200) {
            this.getTemplateData();
            this.createTemplateArray();
            Swal.fire("Deleted!", "Your template has been deleted.", "success");
          } else {
            Swal.fire("Error!", "Your template has not been deleted.", "error");
          }
        });
      }
    });
  }
}
