import { Injectable } from '@angular/core';
import { HttpHeaders, HttpErrorResponse, HttpParams, HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenParms } from 'src/app/core/models/token-parms';
import { Observable, throwError, Subject } from 'rxjs';
import { AuthService } from './auth.service';
import { TesterProfile } from 'src/app/core/models/tester_profile';

@Injectable({
  providedIn: 'root'
})

export class TesterCriteriaService {

  private _header = new HttpHeaders(
    {'Content-Type': 'application/json',
    'usertype': 'admin',
    Authorization: "Token " + this._authServices.getAuthorizationToken()});

  userRoleType = ["tester", "client"];
  usertype = new Subject<any>();
  role = this.usertype.asObservable();
  constructor(private http: HttpClient,private _authServices:AuthService) {}

  getAllTesterList(): Observable<any> {
    return this.http
      .get(environment.apiUrl +"admin/tester_management/")
      .pipe(catchError(this.handleError));
  }

  deletetester(email): Observable<any> {

    const options = {
      headers: new HttpHeaders({
        Authorization: "Token " + this._authServices.getAuthorizationToken()
      }),
      params: new HttpParams()
      .set('email',email),
      reportProgress: false
    };

    return this.http
      .delete(environment.apiUrl + "admin/tester_management/",options)
      .pipe(catchError(this.handleError));
  }


  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }

  register(User: TesterProfile, isToken = false): Observable<any> {
    // localStorage.setItem('roletype',User.roletype)
    return this.postRequestApi(
      environment.apiUrl + "user/registration/",
      User,
      isToken
    ).pipe(catchError(this.handleError));
    // return this.postRequestApi(this.localUrl + 'user/registration/', User, isToken)
    // .pipe(catchError(this.handleError))
  }



  unbanTester(unbanData){
    return this.http
      .post(environment.apiUrl +"admin/tester_banned_unbanned/",unbanData)
      .pipe(catchError(this.handleError));
  }

// ===========================================================================================

  get header(): HttpHeaders {
    return this._header;
  }

  postWithHeader<T>(url: string, body: any | string, headers?: HttpHeaders): Observable<any> {
    return this.http.post(url, body, {headers: headers});
  }

  post<T>(url: string, body: any | string): Observable<T | any> {
    return this.postWithHeader(url, body, this.header);
  }

  postTesterCriteria(bodyJson: any) {
    return this.post(environment.apiUrl + "admin/tester_criteria/", bodyJson)
    .pipe(catchError(this.handleError));
  }

  getWithHeader<T>(url: string, headers?: HttpHeaders): Observable<T> {
    return this.http.get<T>(url, {headers: headers});
  }

  get<T>(url: string): Observable<T> {
    return this.getWithHeader(url, this.header);
  }

  getTesterCriteria(): Observable<any> {
    // return this.get(environment.apiUrl + "admin/tester_criteria/")
    // .pipe(catchError(this.handleError));

    return this.http
    .get(environment.apiUrl +"admin/tester_criteria/")
    .pipe(catchError(this.handleError));
  }

  protected getHttpParamFromObj(queryParams: { [x: string]: any } | undefined): HttpParams {
    let qParams = new HttpParams();
    if (queryParams) {
      for (const key of Object.keys(queryParams)) {
        if (queryParams.hasOwnProperty(key) && queryParams[key]) {
          qParams = qParams.set(key, queryParams[key]);
        }
      }
    }
    return qParams;
  }

  put<T>(url: string, body: any | string, queryParams?: { [x: string]: any }): Observable<T | any> {
    return this.http.put<any>(url, body, {params: this.getHttpParamFromObj(queryParams)});
  }

  updateTesterCriteria(bodyJson: any) {
    return this.put(environment.apiUrl + "admin/tester_criteria/", bodyJson);
  }


  deleteCriteria(bodyJson: any): Observable<any> {
    
    const options = {
      headers: new HttpHeaders({
        Authorization: "Token " + this._authServices.getAuthorizationToken()
      }),
      body: bodyJson
    };

    return this.http
      .delete(environment.apiUrl + "admin/tester_criteria/",options)
      .pipe(catchError(this.handleError));
  }

//========================================================================================== 

  getUnban(email){
    const options = {
      headers: new HttpHeaders({
        Authorization: "Token " + this._authServices.getAuthorizationToken()
      }),
      params: new HttpParams()
      .set('email',email),
      reportProgress: false
    };
    return this.http
      .get(environment.apiUrl + "admin/tester_banned_unbanned/",options)
      .pipe(catchError(this.handleError));
  }

  postRequestApi(url, body = {}, isToken = true) {
    return this.http.post(url, body, this.getHeader(isToken));
  }

  getHeader(isToken, isMultipart = false) {
    let httpOptions: any;

    if (isToken && isMultipart) {
      // httpOptions = {
      //   headers: new HttpHeaders({
      //     "Content-Type": "multipart/form-data",
      //     Authorization: "Token " + this.getToken()
      //   })
      // };
    } else if (isToken) {
      // httpOptions = {
      //   headers: new HttpHeaders({
      //     "Content-Type": "application/json",
      //     Authorization: "Token " + this.getToken()
      //   })
      // };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          USERTYPE: "tester",
        }),
      };
    }
    return httpOptions;
  }
}
