import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: "app-payment-details",
  templateUrl: "./payment-details.component.html",
  styleUrls: ["./payment-details.component.css"],
})
export class PaymentDetailsComponent implements OnInit {
  paymentDetails: any;
  state$: Observable<object>;
  constructor(private _router: Router, public activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.state$ = this.activatedRoute.paramMap.pipe(
      map(() => window.history.state)
    );
    this.getSinglePayementDetails();

    if (!this.paymentDetails.details) {
      // this._router.navigate(['payment/failed/list'])
      window.history.back();
    }
  }

  getSinglePayementDetails() {
    this.state$.subscribe((res) => {
      this.paymentDetails = res;
    });
  }

  backToPayment() {
    // this._router.navigate(['payment/failed/list'])
    window.history.back();
  }
}
