

import { Component, OnInit, ViewChild } from "@angular/core";
import {
  Validators,
  FormGroup,
  FormBuilder,
  FormControl,
} from "@angular/forms";
import { TokenParms } from "src/app/core/models/token-parms";
import Swal from "sweetalert2";
import { PasswordStrengthValidator } from "src/app/constants/validators";
import { TesterCriteriaService } from "src/app/shared/services/tester-criteria.service";
import {
  Sort,
  MatTableDataSource,
  MatPaginator,
  MatSort,
} from "@angular/material";
import { TesterProfile } from "src/app/core/models/tester_profile";

export interface PeriodicElement {
  priority: any;
  testers_count: any;
  id: number;
  rating: any;
  symbol: any;
  delete: any;
}

var ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'app-tester-criteria',
  templateUrl: './tester-criteria.component.html',
  styleUrls: ['./tester-criteria.component.css']
})
export class TesterCriteriaComponent implements OnInit {

//====================
  formType: any;
  formTitleText: any;
//====================

  userList: any;
  error: any;
  allTester: [] = [];
  regForm: FormGroup;
  massage: string;
  registerResMsg: string;
  userRoleType;
  errMsg = [];
  Error = false;
  submitted: boolean = false;
  passwordIsValid = false;
  // @ViewChild(FormGroupDirective ,{static:false}) formGroupDirective: FormGroupDirective;
  registerResEmailMsg: any;
  hideSuccessMessage: boolean = false;
  urlType: string;
  clientSigup = false;
  username: any;
  userUploadTest: [];
  userTestList: [];
  userID: any;
  showUserDetails: boolean = false;
  date: any;
  language: any;
  country: any;
  gender: any;
  userId: any;
  image: any;
  fieldTextType: boolean;
  searchText: string;
  emailTester: any;
  allSortedTester = [];
  pageLength: number = 0;
  pageIndex: number = 0;
  displayedColumns: string[] = [
    "id",
    "priority",
    "testers_count",
    "rating",
    "symbol",
    "delete",
  ];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  totalTestedtestCount: any;
  averageRating: any;
  statusShow: any;
  unban: boolean = false;
  street: any;
  city: any;
  zipcode: any;
  testerDeatils: any;

  @ViewChild(MatPaginator, { static: false }) set content(
    paginator: MatPaginator
  ) {
    this.dataSource.paginator = paginator;
  }

  @ViewChild(MatSort, { static: false }) set conten1t(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  constructor(
    private _testerCriteriaService: TesterCriteriaService,
    private formbuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.showUserDetails = true;
    this.getTesterCriteriaList();
    this.setRegFormState();
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  setRegFormState(): void {

    this.regForm = this.formbuilder.group({
   
      priority: [
        "",
        [
          Validators.required,
        ],
      ],
      testers_count: [
        "",
        [
          Validators.required,
        ],
      ],
      rating: [
        "",
        [
          Validators.required,
        ],
      ]
    });
  }
  
  changeFormType(data, formtxt){
    this.formType = data;
    this.formTitleText = formtxt;
  }


  handleTesterCriteriaForm() {

    if (this.formType == "create-form"){
      
      this._testerCriteriaService.postTesterCriteria(this.regForm.value).subscribe(
        () => {
          this.regForm.reset();
          this.getTesterCriteriaList();
          document.getElementById("closeBtn").click();
        },
        (err) => {
          this.errMsg = err;
        }
      );

    } else if (this.formType == "edit-form"){
      
      this._testerCriteriaService.updateTesterCriteria(this.regForm.value).subscribe(
       ()=>{
         this.regForm.reset();
         this.getTesterCriteriaList();
         document.getElementById("closeBtn").click();
        },
        (err) => {
          this.errMsg = err;
        }
      );
    }
 }

  get f() {
    return this.regForm.controls;
  }


  getTesterCriteriaList() {
    this.userList = this._testerCriteriaService.getTesterCriteria().subscribe(
      (res) => {
        this.dataSource = new MatTableDataSource<PeriodicElement>(
          res.data
        );
        this.allTester = res.data;
        this.allSortedTester = this.allTester;
        this.pageIndex = 0;
        this.pageLength = this.allTester.length;
      },
      (error) => (this.error = error)
    );
  }


  loadTesterCriteriaData(element){
    this.regForm = this.formbuilder.group({
      priority: [
        element.priority,
        [
          Validators.required,
        ],
      ],
      testers_count: [
        element.testers_count,
        [
          Validators.required,
        ],
      ],
      rating: [
        element.rating,
        [
          Validators.required,
        ],
      ],
      id: [
        element.id,
        [],
      ]

    });
  }

  clearForm(){
    this.regForm = this.formbuilder.group({
      priority: [
        "",
        [
          Validators.required,
        ],
      ],
      testers_count: [
        "",
        [
          Validators.required,
        ],
      ],
      rating: [
        "",
        [
          Validators.required,
        ],
      ],

    });
  }
  
  deleteCriteria(bodyJson) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this data ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        this._testerCriteriaService.deleteCriteria(bodyJson).subscribe((res) => {
          this.getTesterCriteriaList();
        });
      }
    });
  }

  // data table

  sortData(sort: Sort) {
    const data = this.allTester.slice();
    if (!sort.active || sort.direction === "") {
      this.allSortedTester = data;
      return;
    }

    this.allSortedTester = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "id":
          return compare(a["id"], b["id"], isAsc);
        case "Username":
          return compare(a["username"], b["username"], isAsc);
        case "Created":
          return compare(a["date_joined"], b["date_joined"], isAsc);
        case "Email":
          return compare(a["email"], b["email"], isAsc);
      
        default:
          return 0;
      }
    });
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
