import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }
// Get Token 

  getToken() {

    return localStorage.getItem('token_id')

  }
  //  Get Request Api

  getRequestApi(url, isToken = true) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.getToken()
      })
    };

    return this.http.get(url, this.getHeader(isToken))
    // return this.http.get(url, httpOptions)
    
  }
  // Post Request Api

  postRequestApi(url, body = {}, isToken = true) {

    return this.http.post(url, body, this.getHeader(isToken))

  }
  // Set Headers

  getHeader(isToken, isMultipart = false) {

    let httpOptions: any;
    if (isToken && isMultipart) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Token ' + this.getToken()
        })
      }
    } else if (isToken) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + this.getToken()
        })
      }
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
    }
    return httpOptions
  }
  
}
