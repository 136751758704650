import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitWords'
})
export class SplitWordsPipe implements PipeTransform {

  transform(value: any, ...args: any[]): string | boolean {
    if (value) {
      let dotPosition = value.indexOf("@");
      return value.substring(0, dotPosition);
    }
    return ''
  }

}
