import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { TransformDirective } from "./transform.directive";
import { HeaderComponent } from "./shared/header/header.component";
import { CoreModule } from "./core/core.module";
// import { TestsClientModule } from "./../app/core/tests-client/tests-client.module";
import { AuthGuard } from "./auth.guard";
import { DashboardModule } from "./core/dashboard/dashboard.module";
// import { AccountModule } from "./modules/account/account.module";
// import { SharedModule } from './shared/shared.module';
// import { TesterModule } from "./modules/tester/tester.module";
import { BnNgIdleService } from "bn-ng-idle";
import { AlertComponent } from "./alert/alert.component";
import { JwtInterceptor } from "./_helpers/jwt.interceptor";
import { ErrorInterceptor } from "./_helpers/error.interceptor";
import { MatBadgeModule } from "@angular/material/badge";
import {
  MatButtonModule,
  MatCheckboxModule,
  MatToolbarModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatCardModule,
  MatMenuModule,
  MatIconModule,
} from "@angular/material";
import { TestsModule } from './modules/tests/tests.module';
import { TestersModule } from './modules/testers/testers.module';
import { UsersModule } from './modules/users/users.module';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { HttpInterceptorService } from './shared/http-interceptor.service';
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { DataTableModule } from 'ng-angular8-datatable';

import { FooterComponent } from "./footer/footer.component";
import { PaymentModule } from "./modules/payment/payment.module";
import { SharedModule } from "./shared/shared.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";

// import { VideosModule } from './modules/videos/videos.module';
// import { VideosRoutingModule } from './modules/videos/videos-routing.module';
// import { AccountRoutingModule } from './modules/account/account-routing.module';
// import { SubscriptionsService } from './shared/services/subscriptions.service';

@NgModule({
  declarations: [
    FooterComponent,
    AppComponent,
    TransformDirective,
    HeaderComponent,
    AlertComponent,
    SidebarComponent,
    PageNotFoundComponent,
   

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    CoreModule,
    // TestsClientModule,
    PaymentModule,
    DashboardModule,
    MatBadgeModule,
    MatButtonModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    TestsModule,
    TestersModule,
    UsersModule,
    DataTableModule,
    SharedModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: "toast-top-right",
    }),
  ],
  exports: [
    MatBadgeModule,
    MatButtonModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
  ],
  providers: [
    AuthGuard,
    BnNgIdleService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    }
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
