import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TesterComponent } from './tester/tester.component';
import { ReactiveFormsModule,FormsModule } from "@angular/forms";
import { NgxSpinnerModule } from "ngx-spinner"; 
import { PasswordStrengthComponent } from 'src/app/core/password-strength/password-strength.component';
import { UsersModule } from '../users/users.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { DataTableModule } from 'ng-angular8-datatable';
import { MatPaginatorModule, MatProgressBarModule, MatProgressSpinnerModule, MatSnackBarModule, MatSortModule, MatTableModule, MatTabsModule, MatToolbarModule, MatTooltipModule, MatTreeModule, MatRadioModule, MatSelectModule, MatSidenavModule, MatSliderModule, MatSlideToggleModule, MatIconModule, MatFormFieldModule, MatInputModule, MatButtonModule } from '@angular/material';
import { TesterCriteriaComponent } from './tester-criteria/tester-criteria.component';




@NgModule({
  declarations: [TesterComponent, TesterCriteriaComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgxSpinnerModule,
    UsersModule,
    SharedModule,
    DataTableModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule
    
  ]
})
export class TestersModule { }
