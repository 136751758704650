import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormArray,
  FormControl,
  Validators,
} from "@angular/forms";
import { NotificationsMangementService } from "src/app/shared/services/notifications-mangement.service";
import Swal from "sweetalert2";


@Component({
  selector: "app-notification-management",
  templateUrl: "./notification-management.component.html",
  styleUrls: ["./notification-management.component.css"],
})
export class NotificationManagementComponent implements OnInit {
  notifcation: FormGroup;
  submitted = false;
  getAllCouponList: any[]=[];

  constructor(
    private _fb: FormBuilder,
    private _notifySer: NotificationsMangementService
  ) {}

  ngOnInit() {
    this.notifcation = this._fb.group({
      coupon_text: ["", Validators.required],
      coupon_message: ["", Validators.required],
    });
    this.getAllCoupon();
  }

  // onAddTests() {
  //   const control = new FormControl(null,[Validators.required,Validators.email]);
  //   (<FormArray>this.notifcation.get("email")).push(control);
  // }

  addNotications() {
    this.submitted = true;
    if (this.notifcation.invalid) {
      return;
    }
    this.submitted = false;
    
    Swal.fire({
      title: "Are you sure?",
      text: "You want to add this coupon!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, add it!",
    }).then((result) => {
      if (result.value) {    
         if(this.getAllCouponList.length<1){          
        this._notifySer
          .sendNotificationsv(this.notifcation.value)
          .subscribe((res) => {
            if (res["status"] == 200) {
              this.notifcation.reset();
              this.getAllCoupon();
              Swal.fire("send!", "Your coupon  has been added.", "success");
            } else {
              Swal.fire("Error!", res["message"], "error");
            }
          });
        } else{
            Swal.fire("Limit!",'You can add a maximum  one coupon!', "error");
        }
      }
    });
  }

  getAllCoupon() {
    this._notifySer.getAllCoupon().subscribe((res) => {
      this.getAllCouponList = res.data;
    });
  }

  deleteCoupon(i, id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this coupon!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        this._notifySer.deleteCoupon(id).subscribe(
          (res) => {
            if (res["status"] == 200) {
              this.getAllCoupon();
              Swal.fire("delete!", "Your coupon  has been deleted.", "success");
            } else {
              Swal.fire("Error!", res["message"], "error");
            }
          },
          (err) => {
            Swal.fire("Error!", err, "error");
          }
        );
      }
    });
  }

  // removeTest(indexoftest): void {
  //   (<FormArray>this.notifcation.get("email")).removeAt(indexoftest);
  // }
}
